import { Checkbox } from "@zakeke/zakeke-ui-widgets";
import { useEffect } from "react";
import { styled } from "styled-components";

import { useGalleryActions, useI18n } from "@/hooks";

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0;
`;

export function GalleryActionsRow({ children }: { children: React.ReactNode }) {
  const { t: tG } = useI18n("gallery");
  const selectedSkus = useGalleryActions((state) => state.selectedSkus);
  const availableSkus = useGalleryActions((state) => state.availableSkus);
  const { toggleAllSkus } = useGalleryActions();

  useEffect(() => {
    return () => {
      toggleAllSkus(false);
    };
  }, [toggleAllSkus]);

  const hasSelectedSkus = selectedSkus.length > 0;

  return (
    <div className="GalleryActions">
      <div className="GalleryActions_row">
        <div className="GalleryActions_selection">
          <StyledCheckbox
            checked={
              selectedSkus.length === availableSkus.length &&
              availableSkus.length > 0
            }
            onChange={(val: boolean) => toggleAllSkus(val)}
          />
          <label className="GalleryActions_selectLabel">
            {tG("actions.selectAll")}{" "}
            {hasSelectedSkus && `(${selectedSkus.length})`}
          </label>
        </div>
        {hasSelectedSkus && children}
      </div>
    </div>
  );
}
