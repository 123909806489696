import { Outlet, useMatch } from "react-router-dom";

import { NavigationSidebar } from "@/components";
import Navbar from "@/components/Navbar";
import routes from "@/configuration/routes";
import useCheckIntegration from "@/hooks/integration/useCheckIntegration.ts";

import "./MainLayout.scss";

export default function MainLayout() {
  const isIntegrationMode = useCheckIntegration();
  const matchData = useMatch(routes.skuReview.path);
  const showMenu = !Boolean(matchData);

  return (
    <>
      {!isIntegrationMode && <Navbar showMenuButton={showMenu} />}
      <div
        className={`main-layout ${isIntegrationMode ? "integration-mode" : ""}`}
      >
        {!isIntegrationMode && showMenu && (
          <nav className="navigation">
            <NavigationSidebar />
          </nav>
        )}
        <Outlet />
      </div>
    </>
  );
}
