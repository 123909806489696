import { ZakekeLoading } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";

import "./FullscreenLoader.scss";

type FullscreenLoaderProps = {
  absolute?: boolean;
};

const FullscreenLoader = ({ absolute = false }: FullscreenLoaderProps) => (
  <div className={classNames("fullscreen-loader", { absolute })}>
    <ZakekeLoading />
  </div>
);

export default FullscreenLoader;
