import { gifVideoDataDefault } from "components/GifVideoForm/constants";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { GalleryType } from "@/@types";
import { ParamsGenerateGifVideoReq, SkuDto } from "@/services";

export type SideBoxContent = "info" | "share" | "vto";

interface IVtoState {
  file?: string;
  productId?: string;
  sceneConfig?: string;
  updatedSceneConfig?: string;
}

type GalleryActionsState = {
  availableSkus: SkuDto[];
  selectedSkus: SkuDto[];
  singleSelectedSku: SkuDto | null;
  isTagsPanelOpen: boolean;
  isTagsModalOpen: boolean;
  sideBoxContent: SideBoxContent;
  isGifVideoModalOpen: boolean;
  gifVideoData: ParamsGenerateGifVideoReq;
  galleryType: GalleryType;
  isVtoModalOpen: boolean;
  vto: IVtoState;
  setGalleryType: (galleryType: GalleryType) => void;
  setAvailableSkus: (skus: SkuDto[]) => void;
  setSelectedSkus: (selectedSkus: SkuDto[]) => void;
  selectSku: (sku: SkuDto, select: boolean) => void;
  setSingleSelectedSku: (sku: SkuDto | null) => void;
  toggleAllSkus: (select?: boolean) => void;
  toggleTagsPanel: (isOpen?: boolean) => void;
  toggleTagsModal: (isOpen?: boolean) => void;
  setSideBoxContent: (content: SideBoxContent) => void;
  toggleGifVideoModal: (isOpen?: boolean) => void;
  setGifVideoData: (gifVideoData: ParamsGenerateGifVideoReq) => void;
  setVto: (vto: IVtoState) => void;
  setVtoFile: (filename?: string) => void;
  setVtoProductId: (productId?: string) => void;
  setVtoSceneConfig: (sceneConfig: string) => void;
  setVtoUpdateSceneConfig: (sceneConfig: string) => void;
  setIsVtoModalOpen: (status: boolean) => void;
};

export const useGalleryActions = create<GalleryActionsState>()(
  devtools(
    subscribeWithSelector((set) => ({
      availableSkus: [],
      selectedSkus: [],
      singleSelectedSku: null,
      isTagsPanelOpen: false,
      isTagsModalOpen: false,
      sideBoxContent: "share",
      isGifVideoModalOpen: false,
      isVtoModalOpen: false,
      gifVideoData: gifVideoDataDefault,
      galleryType: "3d",
      vto: {},
      setVto: (vto: IVtoState) => set({ vto }),
      setVtoFile: (file?: string) =>
        set((state) => ({ vto: { ...state.vto, file } })),
      setVtoProductId: (productId?: string) =>
        set((state) => ({ vto: { ...state.vto, productId } })),
      setVtoSceneConfig: (sceneConfig?: string) =>
        set((state) => ({ vto: { ...state.vto, sceneConfig } })),
      setVtoUpdateSceneConfig: (updatedSceneConfig?: string) =>
        set((state) => ({ vto: { ...state.vto, updatedSceneConfig } })),
      setGalleryType: (galleryType: GalleryType) => set({ galleryType }),
      setAvailableSkus: (skus: SkuDto[]) =>
        set({
          availableSkus: skus.filter((sku) => sku.type !== "PUBLIC_DEMO"),
        }),
      setSelectedSkus: (selectedSkus: SkuDto[]) =>
        set({
          selectedSkus: selectedSkus.filter(
            (sku) => sku.type !== "PUBLIC_DEMO",
          ),
        }),
      selectSku: (sku: SkuDto, select: boolean) => {
        set((state) => {
          const selectedSkus = select
            ? [...state.selectedSkus, sku]
            : state.selectedSkus.filter((s) => s.id !== sku.id);

          if (select) state.setSingleSelectedSku(null);

          return { selectedSkus };
        });
      },
      setSingleSelectedSku: (sku: SkuDto | null) =>
        set({ singleSelectedSku: sku }),
      toggleAllSkus: (select?: boolean) => {
        set((state) => {
          const isSelecting = select ?? state.selectedSkus.length === 0;
          if (select) state.setSingleSelectedSku(null);
          return {
            selectedSkus: isSelecting ? state.availableSkus : [],
          };
        });
      },
      toggleTagsPanel: (isOpen?: boolean) =>
        set((state) => {
          isOpen = isOpen ?? !state.isTagsPanelOpen;
          return { isTagsPanelOpen: isOpen };
        }),
      toggleTagsModal: (isOpen?: boolean) =>
        set((state) => {
          isOpen = isOpen ?? !state.isTagsModalOpen;
          return { isTagsModalOpen: isOpen };
        }),
      setSideBoxContent: (content: SideBoxContent) =>
        set((state) => ({
          sideBoxContent: content,
          isVtoModalOpen:
            state.sideBoxContent === "vto" ? false : state.isVtoModalOpen,
        })),
      toggleGifVideoModal: (isOpen?: boolean) =>
        set((state) => {
          isOpen = isOpen ?? !state.isGifVideoModalOpen;
          return { isGifVideoModalOpen: isOpen };
        }),
      setIsVtoModalOpen: (status: boolean) => set({ isVtoModalOpen: status }),
      setGifVideoData: (gifVideoData: ParamsGenerateGifVideoReq) =>
        set({ gifVideoData }),
    })),
    { name: "GalleryActions" },
  ),
);

useGalleryActions.subscribe(
  (state) => state.selectedSkus,
  (current) => {
    if (current.length === 0) {
      useGalleryActions.getState().toggleTagsPanel(false);
    }
  },
);
