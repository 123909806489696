import {
  TextButton,
  BasicButton as ZakekeBasicButton,
  PrimaryButton as ZakekePrimaryButton,
} from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import { omit } from "lodash";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { css, styled } from "styled-components";

import "./Button.scss";

export type StyledButtonProps = {
  $paddingReset?: boolean;
  $isFullWidth?: boolean;
  $iconImage?: string;
};

const shared = css<StyledButtonProps>`
  ${(props) => props.$paddingReset && "padding: 0;"};
  width: ${(props) => (props.$isFullWidth ? "100%" : "auto")};
  ${(props) =>
    props.$iconImage && "background-color: transparent; border: none;"};
  &:hover,
  &:hover:not([disabled]) {
    ${(props) =>
      props.$iconImage && "background-color: transparent; border: none;"};
  }
  &[disabled] {
    ${(props) =>
      props.$iconImage && "background-color: transparent; border: none;"};
  }
  min-width: 40px;
`;

export const SignInButton = styled(ZakekePrimaryButton)`
  width: 100%;
  height: 36px;
  background-color: #ff9933;
  border-color: #ff9933;

  &:hover {
    background-color: #ffb366 !important;
    border-color: #ffb366;
  }
`;

const BasicButton = styled(ZakekeBasicButton)<StyledButtonProps>`
  ${shared};
`;

const PrimaryButton = styled(ZakekePrimaryButton)<StyledButtonProps>`
  ${shared};
`;

const DestructiveButton = styled(BasicButton)<StyledButtonProps>`
  ${shared};
  background-color: #de1c22;
  color: white;
  border: none;
  &:hover:not([disabled]) {
    background-color: #de4247;
  }
`;

type spacingValues = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type ButtonProps = {
  text?: string | React.ReactNode;
  isFullWidth?: boolean;
  buttonVariant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "text"
    | "destructiveTertiary"
    | "outlineLink"
    | "default";
  buttonSize?: "small" | "medium";
  iconImage?: string;
  icon?: React.ReactNode;
  paddingReset?: boolean;
  btnClasses?: string[];
  margin?: spacingValues;
  onClick?: MouseEventHandler<HTMLElement>;
  ["data-testid"]?: string;
  disabled?: boolean;
  role?: string;
  removeBackground?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
} & (React.ComponentProps<"button"> | ({ toPath: string } & typeof Link));

const Button = ({
  text,
  buttonVariant = "primary",
  buttonSize = "medium",
  isFullWidth = false,
  iconImage,
  icon,
  paddingReset = false,
  btnClasses = [],
  margin = 0,
  removeBackground = false,
  style,
  children,
  ...props
}: ButtonProps) => {
  const className = classNames(`Button ${btnClasses && btnClasses.join(" ")}`, {
    Button___fullWidth: isFullWidth,
    Button___destructive: buttonVariant === "destructive",
    Button___default: buttonVariant === "default",
    Button___small: buttonSize === "small",
    Button___paddingReset: paddingReset,
    Button___icon: icon,
    Button___removeBackground: removeBackground,
    Button___link: "toPath" in props,
    [`Button___marginX${margin}`]: margin !== undefined,
  }) as string;

  const buttonContent = (
    <>
      {iconImage && (
        <img
          className="Button_iconImage"
          src={iconImage}
          alt={text as string}
        />
      )}
      {icon && <div className="Button_iconImage">{icon}</div>}
      {text}
      {children}
    </>
  );

  const buttonProps = {
    onClick: props.onClick,
    role: props.role,
    style: style,
    disabled: props.disabled,
    $paddingReset: paddingReset,
    $isFullWidth: isFullWidth,
    $iconImage: iconImage,
    size: buttonSize,
  };

  if ("toPath" in props) {
    return (
      <Link className={className} to={props.toPath}>
        {buttonContent}
      </Link>
    );
  }

  if (buttonVariant === "primary" || !buttonVariant) {
    return (
      <PrimaryButton data-testid={props["data-testid"]} {...buttonProps}>
        {buttonContent}
      </PrimaryButton>
    );
  }

  if (buttonVariant === "secondary" || buttonVariant === "tertiary") {
    return (
      <BasicButton data-testid={props["data-testid"]} {...buttonProps}>
        {buttonContent}
      </BasicButton>
    );
  }

  if (buttonVariant === "text" || buttonVariant === "destructiveTertiary") {
    const type = buttonVariant === "text" ? "primary" : "destructive";
    return (
      <div
        data-testid={props["data-testid"]}
        className={classNames(className, {
          Button__disabled: props.disabled,
        })}
        onClick={(e) => {
          if (!props.disabled && props.onClick) {
            props.onClick(e);
          }
        }}
      >
        <TextButton type={type}>{buttonContent}</TextButton>
      </div>
    );
  }

  if (buttonVariant === "outlineLink") {
    return (
      <BasicButton
        {...omit(buttonProps, "$iconImage")}
        data-testid={props["data-testid"]}
      >
        {buttonContent}
      </BasicButton>
    );
  }

  if (buttonVariant === "destructive") {
    return (
      <DestructiveButton data-testid={props["data-testid"]} {...buttonProps}>
        {buttonContent}
      </DestructiveButton>
    );
  }

  return (
    <button {...props} onClick={props.onClick} className={className}>
      {buttonContent}
    </button>
  );
};

export default Button;
