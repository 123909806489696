import { Dialog, DialogWindow, Radio } from "@zakeke/zakeke-ui-widgets";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Button } from "@/components/Button";
import { InputField } from "@/components/Input";
import SpinnerWrapper from "@/components/SpinnerWrapper";
import { Tooltip } from "@/components/Tooltip";
import { useI18n } from "@/hooks";
import { formatFileSize, openFile, openImage } from "@/libs";
import { Sku3dModelDto, SkuCreateError } from "@/services";
import deleteIcon from "@/svg/delete.svg?plain";
import uploadIcon from "@/svg/upload.svg?plain";

import "./Sku3DModelForm.scss";

export const MAX_SIZE_WARNING = 25 * 1024 * 1024; // 25 MB
export const MAX_SIZE_WARNING_HDR = 1 * 1024 * 1024; // 1 MB

const ErrorToKey = {
  productId: {
    EXISTING_PRODUCT_ID: "existingProductId",
    GENERIC: "generic",
    REGEX: "regex",
  },
  productName: {
    GENERIC: "generic",
    PRODUCT_NAME: "productName",
  },
};

const Sku3DFileDialogWindow = styled(DialogWindow)`
  flex-basis: 570px;
`;

export function Sku3DModelForm({
  selectedModel: selectedFile,
  setSelectedModel: setSelectedFile,
  onClose,
  onSubmit,
  isTypeViewer,
  isCreateSkuAndUpload3DModel = false,
  isError,
  showDescription = true,
  isLoading = false,
}: {
  selectedModel: Partial<Sku3dModelDto> | null;
  setSelectedModel: (
    value: React.SetStateAction<Partial<Sku3dModelDto> | null>,
  ) => void;
  onClose: () => void;
  onSubmit: (
    local3DFile: File | undefined,
    localEnviromentFile: File | undefined,
    localPreviewFile: File | undefined,
    productName?: string,
    productId?: string,
  ) => void;
  hardReload?: () => void;
  isTypeViewer?: boolean;
  isCreateSkuAndUpload3DModel?: boolean;
  isError?: SkuCreateError;
  skuIdProp?: string;
  showDescription?: boolean;
  isLoading?: boolean;
}) {
  const { t } = useI18n("sku");
  const { t: tR } = useI18n("requests");
  const { t: tC } = useI18n("common");
  const [local3DFile, setLocal3DFile] = useState<File>();
  const [localEnviromentFile, setLocalEnviromentFile] = useState<File>();
  const [localPreviewFile, setLocalPreviewFile] = useState<File>();
  const [localProductId, setLocalProductId] = useState<string | undefined>(
    undefined,
  );
  const [localProductName, setLocalProductName] = useState<string | undefined>(
    undefined,
  );

  const isEditMode = !!selectedFile?.id;

  const canSubmit = useCallback(() => {
    if (isCreateSkuAndUpload3DModel) {
      return !!local3DFile && !!localProductName;
    } else {
      return (
        (isEditMode || !!local3DFile) && (isTypeViewer || !!selectedFile?.type)
      );
    }
  }, [
    isCreateSkuAndUpload3DModel,
    localProductName,
    selectedFile,
    local3DFile,
    isEditMode,
    isTypeViewer,
  ]);

  useLayoutEffect(() => {
    setLocalPreviewFile(undefined);
    setLocal3DFile(undefined);
  }, [selectedFile?.id]);

  const on3DFileSelect = async () => {
    const openedFile = await openFile({
      types: [
        {
          description: "Glb file",
          accept: {
            "application/glb": [".glb"],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false,
    });
    setLocal3DFile(openedFile);
    setSelectedFile((prev) => ({
      ...prev,
      file3dName: openedFile.name,
    }));
  };

  const onEnviromentFileSelect = async () => {
    const openedFile = await openFile({
      types: [
        {
          description: "Hdr file",
          accept: {
            "application/hdr": [".hdr"],
            "application/dds": [".dds"],
            "application/env": [".env"],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false,
    });
    setLocalEnviromentFile(openedFile);
    setSelectedFile((prev) => ({
      ...prev,
      fileEnvironmentName: openedFile.name,
    }));
  };

  const onPreviewFileSelect = async () => {
    const openedFile = await openImage();
    setLocalPreviewFile(openedFile);
    setSelectedFile((prev) => ({
      ...prev,
      filePreviewSize: openedFile.size,
    }));
  };

  const onSubmitClick = async () => {
    if (!canSubmit()) return;

    onSubmit(
      local3DFile,
      localEnviromentFile,
      localPreviewFile,
      localProductName,
      localProductId,
    );
  };

  const previewFileUrl = useMemo(() => {
    if (localPreviewFile) {
      return URL.createObjectURL(localPreviewFile);
    }
    if (selectedFile?.filePreviewUrl) {
      return selectedFile.filePreviewUrl;
    }
    return undefined;
  }, [localPreviewFile, selectedFile]);

  const removePreviewFile = useCallback(async () => {
    setLocalPreviewFile(undefined);
    setSelectedFile((prev) => ({
      ...prev,
      hasFilePreview: false,
      filePreviewSize: undefined,
      filePreviewUrl: undefined,
    }));
  }, []);

  const removeEnvironmentFile = useCallback(async () => {
    setLocalEnviromentFile(undefined);
    setSelectedFile((prev) => ({
      ...prev,
      fileEnvironmentName: undefined,
      fileEnvironmentSize: undefined,
      fileEnvironmentUrl: undefined,
    }));
  }, []);

  const fileEnvironmentInputValue =
    localEnviromentFile || selectedFile?.hasFileEnvironment
      ? selectedFile?.fileEnvironmentName ?? ""
      : "";

  return (
    <Dialog
      showCloseButton={false}
      windowDecorator={Sku3DFileDialogWindow}
      onClose={onClose}
      title={t("sku3DUploader.filesPage.modalTitle")}
      buttons={[
        {
          label: tC("cta.cancel"),
          onClick: onClose,
          outline: true,
        },
        {
          label: tC("cta.confirm"),
          onClick: onSubmitClick,
          disabled: !canSubmit(),
          isSubmit: true,
        },
      ]}
    >
      <SpinnerWrapper spinning={isLoading}>
        <div className="Sku3DFileForm">
          <div className="Sku3DFileForm_main">
            {isCreateSkuAndUpload3DModel && (
              <>
                <div>
                  <div className="Sku3DFileForm_label">
                    {t("sku3DUploader.skuInputName")}
                    <span className="Sku3DFileForm_label__mandatory">*</span>
                    <Tooltip text={tR("form.errors.productName")} />
                  </div>
                  <InputField
                    data-testid="productName"
                    inputSize="small"
                    marginReset
                    value={localProductName ?? ""}
                    onChange={(e) => {
                      setLocalProductName(e.target.value);
                    }}
                    maxLength={120}
                    showError={Boolean(isError?.productName)}
                    description={
                      isError?.productName && (
                        <ErrorMessage
                          message={tR(
                            `form.errors.${
                              ErrorToKey.productName[isError.productName]
                            }`,
                          )}
                        />
                      )
                    }
                  />
                </div>
                <div>
                  <div className="Sku3DFileForm_label">
                    {t("sku3DUploader.skuInputText")}{" "}
                    <Tooltip text={tR("form.errors.regex")} />
                  </div>
                  <InputField
                    data-testid="productId"
                    inputSize="small"
                    marginReset
                    value={localProductId ?? ""}
                    onChange={(e) => {
                      setLocalProductId(e.target.value);
                    }}
                    maxLength={120}
                    showError={!!isError?.productId}
                    description={
                      isError?.productId && (
                        <ErrorMessage
                          message={tR(
                            `form.errors.${
                              ErrorToKey.productId[isError.productId]
                            }`,
                          )}
                        />
                      )
                    }
                  />
                </div>
              </>
            )}
            {!isCreateSkuAndUpload3DModel && showDescription && (
              <div>
                <div className="Sku3DFileForm_label">
                  {t("sku3DUploader.filesPage.form.description")}
                </div>
                <InputField
                  data-testid="description"
                  inputSize="small"
                  marginReset
                  value={selectedFile?.description ?? ""}
                  onChange={(e) => {
                    setSelectedFile((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  maxLength={30}
                />
              </div>
            )}
            <div>
              <div className="Sku3DFileForm_label">
                {t("sku3DUploader.filesPage.form.file3d", {
                  maxSize: formatFileSize(MAX_SIZE_WARNING),
                })}
                <span className="Sku3DFileForm_label__mandatory">*</span>
                <Tooltip
                  text={t("sku3DUploader.filesPage.form.sizeWarning", {
                    maxSize: formatFileSize(MAX_SIZE_WARNING),
                  })}
                />
              </div>
              <div className="Sku3DFileForm_fileRow">
                <div className={"Sku3DFileForm_fileRow___errorMargin"}>
                  <Button
                    text={t("sku3DUploader.filesPage.form.chooseFile")}
                    buttonSize="small"
                    onClick={on3DFileSelect}
                    buttonVariant="secondary"
                    data-testid="uploader"
                  />
                </div>
                <InputField
                  data-testid="3d-file-upload"
                  inputSize="small"
                  marginReset
                  readOnly
                  placeholder="No file selected"
                  value={selectedFile?.file3dName ?? ""}
                  onClick={on3DFileSelect}
                />
              </div>
            </div>

            <div>
              <div className="Sku3DFileForm_label">
                {t("sku3DUploader.filesPage.form.fileEnviroment")}
              </div>
              <div className="Sku3DFileForm_fileRow">
                {selectedFile?.hasFileEnvironment &&
                  selectedFile?.fileEnvironmentName && (
                    <div className={"Sku3DFileForm_fileRow___errorMargin"}>
                      <Button
                        buttonVariant="text"
                        iconImage={deleteIcon}
                        onClick={removeEnvironmentFile}
                      />
                    </div>
                  )}
                <div className={"Sku3DFileForm_fileRow___errorMargin"}>
                  <Button
                    text={t("sku3DUploader.filesPage.form.chooseFile")}
                    buttonSize="small"
                    onClick={onEnviromentFileSelect}
                    buttonVariant="secondary"
                    data-testid="uploader"
                  />
                </div>
                <InputField
                  data-testid="hdr-file-upload"
                  inputSize="small"
                  marginReset
                  readOnly
                  placeholder="No file selected"
                  value={fileEnvironmentInputValue}
                  onClick={onEnviromentFileSelect}
                />
              </div>
            </div>

            {!isCreateSkuAndUpload3DModel && !isTypeViewer && (
              <div>
                <div className="Sku3DFileForm_label">
                  {t("sku3DUploader.filesPage.form.type")}
                  <span className="Sku3DFileForm_label__mandatory">*</span>
                </div>
                <div
                  className="Sku3DFileForm_radioRow"
                  data-testid="radio-choice-type"
                >
                  <Radio
                    label={t("sku3DUploader.filesPage.mesh")}
                    name="radio-mesh"
                    value="checked"
                    checked={selectedFile?.type === "MESH"}
                    onSelect={() => {
                      setSelectedFile((prev) => ({
                        ...prev,
                        type: "MESH",
                      }));
                    }}
                  />
                  <Radio
                    label={t("sku3DUploader.filesPage.material")}
                    name="radio-material"
                    value="checked"
                    checked={selectedFile?.type === "MATERIAL"}
                    onSelect={() => {
                      setSelectedFile((prev) => ({
                        ...prev,
                        type: "MATERIAL",
                      }));
                    }}
                  />
                </div>
              </div>
            )}

            <div>
              <div className="Sku3DFileForm_label">
                {t("sku3DUploader.filesPage.form.preview")}{" "}
              </div>
              <div
                className={`Sku3DFileForm_imageRow ${
                  previewFileUrl ? "" : "Sku3DFileForm_imageRow___empty"
                }`}
              >
                {previewFileUrl ? (
                  <>
                    <Button
                      buttonVariant="text"
                      iconImage={deleteIcon}
                      btnClasses={["Sku3DFileForm_imageRow___deleteImage"]}
                      onClick={removePreviewFile}
                    />
                    <img src={previewFileUrl} alt="" />
                  </>
                ) : (
                  <Button
                    text={t("sku3DUploader.filesPage.form.uploadImage")}
                    onClick={onPreviewFileSelect}
                    iconImage={uploadIcon}
                    btnClasses={["Sku3DFileForm_imageRow___uploadButton"]}
                    buttonVariant="default"
                    data-testid="uploader-img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </SpinnerWrapper>
    </Dialog>
  );
}

const ErrorMessage = ({ message }: { message: string }) => (
  <div className="Sku3DFileForm_errorMessage">{message}</div>
);
