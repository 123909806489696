import { useContext } from "react";

import { AuthContext } from "@/contexts/Auth.tsx";

const useAuth = () => {
  const actorRef = useContext(AuthContext);
  if (!actorRef) {
    throw new Error("useUser must be called in a valid AuthContext");
  }
  return actorRef;
};

export default useAuth;
