import classNames from "classnames";
import { RouteDefinition } from "configuration/routes.ts";
import { NavLink, useMatch } from "react-router-dom";

import { LoggedUser } from "@/@types";
import { useSidebar } from "@/hooks";
import { initials } from "@/libs";

type ItemProps = {
  user: LoggedUser;
  label: string;
  route: RouteDefinition;
  icon?: React.ReactNode;
  subItems?: Omit<ItemProps, "user">[];
};

const Item = ({ user, label, route, icon, subItems }: ItemProps) => {
  const isSidebarOpen = useSidebar((s) => s.isSidebarOpen);

  const match = useMatch(`${route.path}/*`);

  if (
    !route ||
    !route.userGroupRestrictions ||
    !route.userGroupRestrictions.includes(user.groupCode)
  ) {
    return null;
  }

  const getIconContent = () => {
    if (icon) {
      return icon;
    }

    return isSidebarOpen ? "" : initials(label);
  };

  return (
    <div>
      <NavLink
        to={route?.path ?? "./"}
        className={classNames("NavigationSidebar_item", {
          NavigationSidebar_item___active: match,
        })}
      >
        <span className="NavigationSidebar_itemIcon">{getIconContent()}</span>
        {isSidebarOpen && (
          <div className="NavigationSidebar_itemLabel">{label}</div>
        )}
      </NavLink>
      {subItems && (
        <div className="NavigationSidebar_subItems">
          {subItems.map((item) => (
            <Item
              user={user}
              key={item.route.path}
              label={item.label}
              route={item.route}
              icon={item.icon}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Item;
