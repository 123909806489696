import { AnyActorRef, DoneActorEvent, assign, sendTo, setup } from "xstate";

import { listOrganizations } from "@/blackbox/machines/organization/actors.ts";
import { assignError } from "@/blackbox/machines/utils.ts";
import { OrganizationDto, OrganizationsDto } from "@/services";

type CompanySelectInput = {
  partnerId: string | null;
  selectedOrganizationId: string | null;
  authActorRef: AnyActorRef | null;
};

type CompanySelectContext = CompanySelectInput & {
  page: number;
  size: number;
  organizations: OrganizationDto[];
  error: unknown;
};

type SelectEvent = { type: "select"; payload: { organizationId: string } };

type CompanySelectEvents = { type: "load" } | SelectEvent;

const machine = setup({
  types: {
    context: {} as CompanySelectContext,
    input: {} as CompanySelectInput,
    events: {} as CompanySelectEvents,
  },
  actions: {
    // @ts-ignore
    assignError,
  },
  actors: {
    list: listOrganizations,
  },
}).createMachine({
  context: ({ input }) => ({
    ...input,
    page: 1,
    size: 100,
    organizations: [],
    error: null,
  }),
  initial: "idle",
  states: {
    idle: {
      on: {
        load: {
          target: "pending",
        },
        select: {
          actions: [
            assign({
              selectedOrganizationId: ({ event }) =>
                event.payload.organizationId,
            }),
            sendTo(
              // @ts-ignore
              ({ context }) => context.authActorRef,
              ({ event }: { event: SelectEvent }) => ({
                type: "assignOrganization",
                payload: { organizationId: event.payload.organizationId },
              }),
            ),
          ],
        },
      },
    },
    pending: {
      invoke: {
        src: "list",
        id: "select-companies",
        input: ({ context }) => ({
          size: context.size,
          page: context.page,
          partnerId: context.partnerId,
        }),
        onDone: {
          target: "idle",
          actions: [
            assign({
              organizations: ({ event }) =>
                (event as DoneActorEvent<OrganizationsDto>).output.result,
              selectedOrganizationId: ({ context, event }) => {
                if (context.selectedOrganizationId) {
                  return context.selectedOrganizationId;
                }

                const result = (event as DoneActorEvent<OrganizationsDto>)
                  .output.result;
                const organization = context.selectedOrganizationId
                  ? result.find(
                      (org) => org.id === context.selectedOrganizationId,
                    )
                  : null;
                return organization?.id ?? result[0]?.id ?? null;
              },
            }),
            sendTo(
              // @ts-ignore
              ({ context }) => context.authActorRef,
              ({ context }: { context: CompanySelectContext }) =>
                context.selectedOrganizationId
                  ? {
                      type: "assignOrganization",
                      payload: {
                        organizationId: context.selectedOrganizationId,
                      },
                    }
                  : null,
            ),
          ],
        },
        onError: {
          target: "idle",
          actions: [
            assign({
              organizations: [],
            }),
            "assignError",
          ],
        },
      },
    },
  },
});

export default machine;
