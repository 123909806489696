import { PaginationResponseBuilder } from "services/common/models/pagination";
import { SkuProjectType } from "services/http/sku/models";
import { z } from "zod";

export const OrderStatusCode = z.union([
  z.literal("IN_PROGRESS"),
  z.literal("PREVENTIVE"),
  z.literal("ACCEPTED"),
  z.literal("REJECTED"),
]);
export type OrderStatusCode = z.infer<typeof OrderStatusCode>;
//order detail
export const OrderDto = z.object({
  id: z.string().uuid(),
  companyId: z.string().uuid(),
  number: z.string(),
  description: z.string(),
  statusCode: OrderStatusCode,
  statusName: z.string(),
  projectType: SkuProjectType,
  requestedSku: z.number(),
  createdSku: z.number(),
  fileUrl: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  attachmentUrl: z.string().optional(),
});

export type OrderDto = z.infer<typeof OrderDto>;

//order list
export const OrdersDto = PaginationResponseBuilder(OrderDto);
export type OrdersDto = z.infer<typeof OrdersDto>;

//order querystring
export const OrderQuerystringDto = z.object({
  companyId: z.string().uuid().optional(),
  statusCode: z.optional(OrderStatusCode),
  projectType: z.optional(SkuProjectType),
  sortBy: z.optional(
    z.union([
      z.literal("number.asc"),
      z.literal("number.desc"),
      z.literal("createdAt.asc"),
      z.literal("createdAt.desc"),
    ]),
  ),
});

export type OrderQuerystringDto = z.infer<typeof OrderQuerystringDto>;

export const UpdateOrderDto = z.object({
  statusCode: z.optional(OrderStatusCode),
  hasAttachment: z.optional(z.boolean()),
  requestedSku: z.optional(z.number()),
});

export type UpdateOrderDto = z.infer<typeof UpdateOrderDto>;

export const OrderCreateDto = z.object({
  statusCode: OrderStatusCode,
  description: z.string(),
  projectType: SkuProjectType,
  companyId: z.string().uuid(),
  requestedSku: z.number(),
});

export type OrderCreateDto = z.infer<typeof OrderCreateDto>;
