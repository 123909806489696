// FF
export const getAccessToken = (): string | null =>
  sessionStorage.getItem("access-token");
export const getRefreshToken = (): string | null =>
  localStorage.getItem("refresh-token");

export const setAccessToken = (token?: string | null): void => {
  if (!token) {
    return sessionStorage.removeItem("access-token");
  }

  sessionStorage.setItem("access-token", token);
};

export const setRefreshToken = (token?: string | null): void => {
  if (!token) {
    return localStorage.removeItem("refresh-token");
  }

  localStorage.setItem("refresh-token", token);
};

// Integration
export const getIntegrationJwt = (): string | null =>
  sessionStorage.getItem("integration-jwt");

export const setIntegrationJwt = (token?: string | null): void => {
  if (!token) {
    return sessionStorage.removeItem("integration-jwt");
  }

  sessionStorage.setItem("integration-jwt", token);
};

export const getIntegrationIdToken = (): string | null =>
  sessionStorage.getItem("integration-id");

export const setIntegrationIdToken = (token?: string | null): void => {
  if (!token) {
    return sessionStorage.removeItem("integration-id");
  }

  sessionStorage.setItem("integration-id", token);
};
