import { z } from "zod";

export const ViewsDto = z.object({
  ARViews: z.number(),
  Views: z.number(),
  Date: z.string(),
});

export const ViewsDtoList = z.array(ViewsDto);

export const DeviceViewsDto = z.object({
  Android: z.number(),
  Desktop: z.number(),
  iOS: z.number(),
  Date: z.string(),
});

export const DeviceViewsList = z.array(DeviceViewsDto);

export const AnalyticsTableDto = z.object({
  product: z.string(),
  total: z.number(),
  AR: z.number(),
  iOS: z.number(),
  Android: z.number(),
  Desktop: z.number(),
  totalViews: z.number().optional(),
});

export const AnalyticsTableList = z.array(AnalyticsTableDto);

export type ViewsDto = z.infer<typeof ViewsDto>;
export type ViewsDtoList = z.infer<typeof ViewsDtoList>;

export type DeviceViewsDto = z.infer<typeof DeviceViewsDto>;
export type DeviceViewsList = z.infer<typeof DeviceViewsList>;

export type AnalyticsTableDto = z.infer<typeof AnalyticsTableDto>;
export type AnalyticsTableList = z.infer<typeof AnalyticsTableList>;

export type AnalyticsExportData = z.infer<typeof AnalyticsExportData>;
export const AnalyticsExportData = z.any();
