import { Navigate } from "react-router-dom";

import { useAuthentication } from "@/hooks";
import { createLogger } from "@/libs";

const logger = createLogger("RootRouteRedirector");

export default function RootRouteRedirector() {
  // TODO change this

  const { user, isLoading, afterLoginRedirectRoute } = useAuthentication();
  logger.debug("RootRouteRedirector", { user, isLoading });

  if (isLoading) return null;
  if (user) return <Navigate to={afterLoginRedirectRoute} replace />;
  return <Navigate to="/sign-in" replace />;
}
