import * as Sentry from "@sentry/browser";
import { Component, ReactNode } from "react";

export class ErrorBoundary extends Component<{ children: ReactNode }> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as Record<string, unknown>);
      Sentry.captureException(error);
    });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error,
    };
  }

  render() {
    // if an error occurred
    if (this.state.hasError) {
      return (
        <div role="alert">
          <p>Something went wrong:</p>
          <p style={{ color: "red" }}>{this.state.error.message}</p>
          <pre>{this.state.error.stack}</pre>
        </div>
      );
    } else {
      // default behavior
      return this.props.children;
    }
  }
}
