import { PaginationQuery } from "services/common/models/pagination";
import { saasApiClient } from "services/http/saas-api-client";

import { CreateUserDto, UserDto } from "../user";
import {
  BrandDto,
  BrandFiltersDto,
  BrandsDto,
  CreateBrandDto,
  UpdateBrandDto,
} from "./models";

const entityPath = "/brands";

const list = (
  filters?: BrandFiltersDto,
  pagination?: PaginationQuery,
): Promise<BrandsDto> =>
  saasApiClient.getPaginated(`${entityPath}`, BrandsDto, pagination, filters);

const create = (brand: CreateBrandDto): Promise<BrandDto> =>
  saasApiClient.post(`${entityPath}`, brand, BrandDto);

const get = (id: string): Promise<BrandDto> =>
  saasApiClient.get(`${entityPath}/${id}`, BrandDto);

const remove = (id: string): Promise<BrandDto> =>
  saasApiClient.delete(`${entityPath}/${id}`);

const update = (brand: UpdateBrandDto): Promise<BrandDto> =>
  saasApiClient.patch(`${entityPath}/${brand.id}`, brand);

const getByCompanyId = (id: string): Promise<BrandsDto> =>
  saasApiClient.get(`${entityPath}?companyId=${id}`, BrandsDto);

const user = {
  create: (brandId: string, user: CreateUserDto): Promise<UserDto> =>
    saasApiClient.post(`${entityPath}/${brandId}/users/`, user, UserDto),
};

export const brandHttpClient = {
  list,
  create,
  get,
  getByCompanyId,
  remove,
  update,
  user,
};
