import { fromPromise } from "xstate";

import routes, { RouteDefinition } from "@/configuration/routes.ts";
import { MenuItems } from "@/services/http/menu-items.ts";
import { saasApiClient } from "@/services/http/saas-api-client.ts";

export type MenuItem = MenuItems["result"][0] & {
  route?: RouteDefinition;
};

const machine = fromPromise(async () => {
  const resp = await saasApiClient.get(`/menu-items`, MenuItems);
  const items = resp.result.map<MenuItem>((item) => {
    const route = Object.values(routes).find(
      (routeDefinition) => routeDefinition.path === item.path,
    );

    return {
      ...item,
      route,
    };
  });

  return items;
});

export default machine;
