import useUser from "hooks/auth/useUser.ts";

import { UserGroupCode } from "@/services";

const useCheckUserGroup = () => {
  const user = useUser();

  return (group: UserGroupCode | UserGroupCode[]) =>
    user?.groupCode ? group.includes(user.groupCode) : false;
};

export default useCheckUserGroup;
