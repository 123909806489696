export const fileDownloader = async (url: string, fileName?: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    // Create a new anchor element
    const downloadLink = document.createElement("a");
    const objectURL = URL.createObjectURL(blob);
    const downloadedFileName = getFileNameFromURL(url);

    downloadLink.href = objectURL; // Set the URL of the image
    downloadLink.download = fileName ?? downloadedFileName ?? ""; // Set the desired filename for the image download

    // Append the anchor element to the document
    document.body.appendChild(downloadLink);

    // Programmatically trigger a click event on the anchor element
    downloadLink.click();

    // Clean up: Remove the anchor element and the object URL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(objectURL);
  } catch (error) {
    return null;
  }
};

function getFileNameFromURL(url: string) {
  return url.split("/").pop();
}
