import { ParamsGenerateGifVideoReq } from "@/services";

export const gifVideoDataDefault: ParamsGenerateGifVideoReq = {
  width: 600,
  height: 0,
  fps: 18,
  duration: 5,
  offset: 0,
  zoom: 0,
  isGif: true,
  isVideo: true,
  backgroundColor: "#E6E6E6",
};
