import { saasApiClient } from "services/http/saas-api-client";

import { StatusListDto } from "./models";

const entityPath = "/sku-statuses";

const list = (): Promise<StatusListDto> =>
  saasApiClient.getPaginated(`${entityPath}/`, StatusListDto);

export const statusHttpClient = {
  list,
};
