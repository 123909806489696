import classNames from "classnames";
import { useMemo } from "react";
import Select, { GroupBase, Props } from "react-select";
import AsyncSelect, { AsyncProps } from "react-select/async";

import { InputSize } from "@/@types";
import { useI18n } from "@/hooks";

import "./AutocompleteField.scss";

export type AutocompleteFieldProps<
  T,
  IsMulti extends boolean,
  Group extends GroupBase<T>,
> =
  | ({
      label?: string;
      inputSize?: InputSize;
      async?: true;
      hidden?: boolean;
    } & AsyncProps<T, IsMulti, Group>)
  | ({
      label?: string;
      inputSize?: InputSize;
      async?: false;
      hidden?: boolean;
    } & Props<T, IsMulti, Group>);

export function AutocompleteField<
  T,
  IsMulti extends boolean,
  Group extends GroupBase<T>,
>({
  label,
  inputSize = "standard",
  async = true,
  hidden = false,
  ...props
}: AutocompleteFieldProps<T, IsMulti, Group>) {
  const { t } = useI18n("common");

  const loca = useMemo(() => {
    return {
      noOptionsMessage: () => t("autocomplete.noOptions"),
      loadingMessage: () => t("autocomplete.loading"),
      placeholder: t("autocomplete.select"),
    };
  }, [t]);

  const selectProps = {
    classNamePrefix: "AutocompleteField_select_",
    unstyled: true,
  };

  return (
    <div
      className={classNames("AutocompleteField", {
        AutocompleteField___small: inputSize === "small",
      })}
      style={{ visibility: hidden ? "hidden" : undefined }}
    >
      {label && <label className="AutocompleteField_label">{label}</label>}
      {async ? (
        <AsyncSelect {...loca} {...selectProps} {...props} />
      ) : (
        <Select {...loca} {...selectProps} {...props} />
      )}
    </div>
  );
}
