import axios, { AxiosError, AxiosProgressEvent } from "axios";
import { TypeOf, ZodTypeAny } from "zod";

import {
  getIntegrationIdToken,
  getIntegrationJwt,
} from "@/blackbox/storage/auth.ts";
import { PaginationQuery } from "@/services";

import * as R from "./Result";
import {
  HttpAbortError,
  HttpBadRequestError,
  HttpForbiddenError,
  HttpInternalServerError,
  HttpUnauthorizedError,
  ValidationError,
} from "./errors";
import { createLogger } from "./logger";
import { isDefinedTupleValue } from "./tuple";
import { validationSafe } from "./validation";

export type RequestConfig = {
  headers?: Record<string, string | number | boolean>;
  abort?: AbortController;
};

export type EventCallbacks = {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
};

type RequestOptions<ResponseSchema extends ZodTypeAny = never, Body = never> = {
  basePath: string;
  method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
  endpoint: string;
  schema?: ResponseSchema;
  body?: Body;
  config?: RequestConfig;
  eventCallbacks?: EventCallbacks;
};

type BeforeRequestFn = (config: RequestConfig) => Promise<RequestConfig>;

function httpClient(basePath: string, onBeforeRequests: BeforeRequestFn[]) {
  const logger = createLogger(`http-client: basePath=${basePath}`);
  async function request<
    ResponseSchema extends ZodTypeAny = never,
    Body = never,
  >(
    opts: RequestOptions<ResponseSchema, Body>,
  ): Promise<
    ResponseSchema extends never ? undefined : TypeOf<ResponseSchema>
  > {
    let config: RequestConfig = {
      headers: {
        "Content-Type": "application/json",
        ...(opts.config?.headers || {}),
      },
      abort: opts.config?.abort,
    };
    for (const fn of onBeforeRequests) {
      config = await fn(config);
    }

    try {
      const response = await axios(`${opts.basePath}${opts.endpoint}`, {
        method: opts.method,
        data: opts.body,
        headers: config.headers,
        signal: config.abort?.signal,

        onUploadProgress: opts.eventCallbacks?.onUploadProgress,
        onDownloadProgress: opts.eventCallbacks?.onDownloadProgress,
      });

      if (!opts.schema)
        return undefined as ResponseSchema extends never
          ? undefined
          : TypeOf<ResponseSchema>;

      const result = validationSafe(opts.schema, response.data);
      if (R.isSuccess(result)) {
        return result.value;
      }
      throw result.error;
    } catch (axiosError) {
      if (axios.isCancel(axiosError)) {
        logger.info("request cancelled");
        throw new HttpAbortError();
      }

      if (axiosError instanceof ValidationError) {
        logger.error(axiosError, "Response validation failed", axiosError);
        throw axiosError;
      }

      logger.error(
        axiosError as Error,
        (axiosError as Error)?.message,
        "request failed",
      );

      if (axiosError instanceof AxiosError && axiosError.response) {
        const { response } = axiosError;

        if (response.status === 403) {
          throw new HttpForbiddenError(
            JSON.stringify(response.data),
            response.data?.customCode,
          );
        }

        if (response.status === 401) {
          const integrationJwt = getIntegrationJwt();
          const integrationId = getIntegrationIdToken();

          if (
            !opts.endpoint.includes("users") &&
            !opts.endpoint.includes("auth") &&
            !opts.endpoint.includes("analytics") &&
            !integrationJwt
          ) {
            window.top?.location.reload();
          }

          if (
            !opts.endpoint.includes("users") &&
            !opts.endpoint.includes("auth") &&
            !opts.endpoint.includes("analytics") &&
            integrationJwt
          ) {
            const redirectUrl = `${window.location.origin}/access/jwt?jwt=${integrationJwt}&api_token=${integrationId}&return_url=${window.location.pathname}`;
            window.location.href = redirectUrl;
          }

          throw new HttpUnauthorizedError(JSON.stringify(response.data));
        }

        if (response.status === 400) {
          throw new HttpBadRequestError(JSON.stringify(response.data));
        }

        throw new HttpInternalServerError(JSON.stringify(response.data));
      }

      throw axiosError;
    }
  }

  const getPaginated = <
    ResponseSchema extends ZodTypeAny,
    Filters extends Record<string, string | number | boolean | string[]>,
  >(
    endpoint: string,
    schema: ResponseSchema,
    paginationQuery: PaginationQuery = {
      offset: 0,
      limit: 100,
    },
    filters?: Filters,
    config?: RequestConfig,
  ) => {
    const qs = new URLSearchParams({
      ...Object.entries(Object.assign({}, paginationQuery, filters))
        .filter(isDefinedTupleValue)
        .reduce<Record<string, string>>((acc, [k, v]) => {
          acc[k] = v.toString();
          return acc;
        }, {}),
    }).toString();
    return request<ResponseSchema>({
      basePath,
      method: "GET",
      endpoint: `${endpoint}?${qs}`,
      schema,
      config,
    });
  };

  const get = <ResponseSchema extends ZodTypeAny>(
    endpoint: string,
    schema: ResponseSchema,
    config?: RequestConfig,
  ) => {
    return request<ResponseSchema>({
      basePath,
      method: "GET",
      endpoint,
      schema,
      config,
    });
  };

  const post = <Body, ResponseSchema extends ZodTypeAny = never>(
    endpoint: string,
    body?: Body,
    schema?: ResponseSchema,
    config?: RequestConfig,
  ) => {
    return request<ResponseSchema, Body>({
      basePath,
      method: "POST",
      endpoint,
      body,
      schema,
      config,
    });
  };

  const put = <Body, ResponseSchema extends ZodTypeAny = never>(
    endpoint: string,
    body: Body,
    schema?: ResponseSchema,
    config?: RequestConfig,
    eventCallbacks?: EventCallbacks,
  ) => {
    return request<ResponseSchema, Body>({
      basePath,
      method: "PUT",
      endpoint,
      body,
      schema,
      config,
      eventCallbacks,
    });
  };

  const patch = <Body, ResponseSchema extends ZodTypeAny = never>(
    endpoint: string,
    body: Body,
    schema?: ResponseSchema,
    config?: RequestConfig,
  ) => {
    return request<ResponseSchema, Body>({
      basePath,
      method: "PATCH",
      endpoint,
      body,
      schema,
      config,
    });
  };

  const remove = <ResponseSchema extends ZodTypeAny = never>(
    endpoint: string,
    schema?: ResponseSchema,
    config?: RequestConfig,
  ) => {
    return request<ResponseSchema>({
      basePath,
      method: "DELETE",
      endpoint,
      schema,
      config,
    });
  };

  return {
    get,
    getPaginated,
    post,
    put,
    patch,
    delete: remove,
  };
}

export class HttpClientBuilder {
  private onBeforeRequests: BeforeRequestFn[] = [];

  constructor(private readonly basePath: string) {}

  addOnBeforeRequest(fn: BeforeRequestFn): HttpClientBuilder {
    this.onBeforeRequests.push(fn);

    return this;
  }

  build() {
    return httpClient(this.basePath, this.onBeforeRequests);
  }
}

export type HttpClient = ReturnType<typeof httpClient>;
