import { Select } from "@zakeke/zakeke-ui-widgets";
import { styled } from "styled-components";

import { Button } from "@/components";
import { useI18n } from "@/hooks";
import { PaginationResponse } from "@/services";
import arrowFirst from "@/svg/arrow-first-page.svg?plain";
import arrowLast from "@/svg/arrow-last-page.svg?plain";
import arrowPrev from "@/svg/arrow-left.svg?plain";
import arrowNext from "@/svg/arrow-right.svg?plain";

import "./PaginationBar.scss";

const StyledSelect = styled(Select)`
  padding: 0 0 0 5px;
  max-width: 56px;
  font-size: 12px;
  max-height: 30px;
  outline: none;
`;

const selectOptions = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

export type PaginationBarProps = {
  paginationData?: PaginationResponse;
  pageSize: number;
  label?: string;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (pageIndex: number) => void;
  disabled?: boolean;
};

export function PaginationBar({
  paginationData,
  pageSize,
  onPageSizeChange,
  onPageChange,
  label,
  disabled,
}: PaginationBarProps) {
  const { t } = useI18n();
  const leftLabel = label || t("pagination.genericPerPage");

  const firstItemIndex = paginationData
    ? (paginationData.pageIndex - 1) * paginationData.pageSize
    : 0;
  const lastItemIndex = paginationData
    ? Math.min(
        firstItemIndex + paginationData.pageSize,
        paginationData.itemCount,
      )
    : 0;
  const totItems = paginationData?.itemCount ?? 0;

  return (
    <div className="PaginationBar">
      <div className="PaginationBar_left">
        {leftLabel}{" "}
        <StyledSelect
          disabled={disabled}
          value={pageSize.toString()}
          onChange={(val: string) => onPageSizeChange(+Number(val))}
        >
          {selectOptions.map((b) => (
            <option key={b.value} value={b.value}>
              {b.label}
            </option>
          ))}
        </StyledSelect>
      </div>
      <div className="PaginationBar_right">
        {firstItemIndex + 1} - {lastItemIndex} {t("pagination.of")} {totItems}
        <div className="PaginationBar_buttonsBar">
          <Button
            disabled={disabled}
            text={undefined}
            buttonVariant="tertiary"
            iconImage={arrowFirst}
            paddingReset
            onClick={() => {
              onPageChange(1);
            }}
          />
          <Button
            disabled={disabled}
            text={undefined}
            buttonVariant="tertiary"
            iconImage={arrowPrev}
            paddingReset
            onClick={() => {
              if (!paginationData?.pageIndex) return;

              onPageChange(paginationData.pageIndex - 1);
            }}
          />
          <Button
            disabled={disabled}
            text={undefined}
            buttonVariant="tertiary"
            iconImage={arrowNext}
            paddingReset
            onClick={() => {
              if (!paginationData?.pageIndex) return;

              onPageChange(paginationData.pageIndex + 1);
            }}
          />
          <Button
            disabled={disabled}
            text={undefined}
            buttonVariant="tertiary"
            iconImage={arrowLast}
            paddingReset
            onClick={() => {
              onPageChange(paginationData?.pageCount ?? 1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
