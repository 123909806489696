import { createBrowserInspector } from "@statelyai/inspect";
import { InspectionEvent, Observer, assign } from "xstate";

export const getDebugInspector = (): Observer<InspectionEvent> | undefined => {
  if (import.meta.env.DEV) {
    const { inspect } = createBrowserInspector({ autoStart: true });
    return inspect;
  }
};

// TODO refactor to use params https://stately.ai/docs/actions#actions-and-typescript
export const assignError = assign({
  error: ({ event }: { event: { error: unknown } }) => event.error,
});
