import { EventCallbacks, HttpClientBuilder } from "libs/http-client";
import { PaginationQuery } from "services/common/models/pagination";
import { saasApiClient } from "services/http/saas-api-client";

import { LogoType } from "@/@types";

import { PartnersDto } from "../partner";
import {
  CompaniesDto,
  CompanyDto,
  CompanyFiltersDto,
  CompanyPartnerFiltersDto,
  CreateCompanyDto,
  UpdateCompanyDto,
} from "./models";

const entityPath = "/companies";

const images = {
  updateAndPutImage: async (
    companyId: string,
    file: File,
    logoType: LogoType,
    onUploadProgressCallBack?: (progressEvent: ProgressEvent) => void,
  ): Promise<CompanyDto> => {
    const uploadedImage = await update(companyId, {
      hasSquareLogo: logoType === "squareLogo" ? false : undefined,
      hasHorizontalLogo: logoType === "horizontalLogo" ? false : undefined,
    } as UpdateCompanyDto);
    const eventCallbacks: EventCallbacks = {
      onUploadProgress: onUploadProgressCallBack,
    };
    const logoUrl =
      logoType === "horizontalLogo"
        ? uploadedImage.horizontalLogoUrl
        : uploadedImage.squareLogoUrl;
    await new HttpClientBuilder(logoUrl!!)
      .build()
      .put("", file, undefined, undefined, eventCallbacks);
    await update(companyId, {
      hasSquareLogo: logoType === "squareLogo" || undefined,
      hasHorizontalLogo: logoType === "horizontalLogo" || undefined,
    } as UpdateCompanyDto);

    return uploadedImage;
  },
  delete: async (
    companyId: string,
    logoFlags: Pick<UpdateCompanyDto, "hasHorizontalLogo" | "hasSquareLogo">,
  ): Promise<CompanyDto> => await update(companyId, logoFlags),
};

const partners = {
  list: (
    companyId: string,
    filters?: CompanyPartnerFiltersDto,
    pagination?: PaginationQuery,
  ): Promise<PartnersDto> =>
    saasApiClient.getPaginated(
      `${entityPath}/${companyId}/partners`,
      PartnersDto,
      pagination,
      filters,
    ),
};

const update = (id: string, company: UpdateCompanyDto): Promise<CompanyDto> =>
  saasApiClient.patch(`${entityPath}/${id}`, company, CompanyDto);

const create = (company: CreateCompanyDto): Promise<CompanyDto> =>
  saasApiClient.post(`${entityPath}`, company, CompanyDto);

const get = (id: string): Promise<CompanyDto> =>
  saasApiClient.get(`${entityPath}/${id}`, CompanyDto);

const list = (
  filters?: CompanyFiltersDto,
  pagination?: PaginationQuery,
): Promise<CompaniesDto> =>
  saasApiClient.getPaginated(
    `${entityPath}`,
    CompaniesDto,
    pagination,
    filters,
  );

const remove = (id: string): Promise<void> =>
  saasApiClient.delete(`${entityPath}/${id}`);

export const companyHttpClient = {
  create,
  get,
  list,
  update,
  remove,
  images,
  partners,
};
