import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

//partner detail
export const PartnerDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  vat: z.optional(z.string()),
  fiscalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  province: z.optional(z.string()),
  city: z.optional(z.string()),
  zipCode: z.optional(z.string()),
  street: z.optional(z.string()),
  email: z.optional(z.string().email()),
  phone: z.optional(z.string()),
  pmCount: z.number(),
  artist3dCount: z.number(),
});

export type PartnerDto = z.infer<typeof PartnerDto>;

//partner list
export const PartnersDto = PaginationResponseBuilder(PartnerDto);
export type PartnersDto = z.infer<typeof PartnersDto>;

//create partner
export const CreatePartnerDto = z.object({
  name: z.string().min(1),
  vat: z.optional(z.union([z.string().min(1), z.null()])),
  fiscalCode: z.optional(z.union([z.string().min(1), z.null()])),
  country: z.optional(z.union([z.string().min(1), z.null()])),
  province: z.optional(z.union([z.string().min(1), z.null()])),
  city: z.optional(z.union([z.string().min(1), z.null()])),
  zipCode: z.optional(z.union([z.string().min(1), z.null()])),
  street: z.optional(z.union([z.string().min(1), z.null()])),
  email: z.optional(z.union([z.string().email(), z.null()])),
  phone: z.optional(z.union([z.string().min(1), z.null()])),
});

export type CreatePartnerDto = z.infer<typeof CreatePartnerDto>;

//update partner
export const UpdatePartnerDto = z.object({
  name: z.optional(z.string().min(1)),
  vat: z.optional(z.union([z.string().min(1), z.null()])),
  fiscalCode: z.optional(z.union([z.string().min(1), z.null()])),
  country: z.optional(z.union([z.string().min(1), z.null()])),
  province: z.optional(z.union([z.string().min(1), z.null()])),
  city: z.optional(z.union([z.string().min(1), z.null()])),
  zipCode: z.optional(z.union([z.string().min(1), z.null()])),
  street: z.optional(z.union([z.string().min(1), z.null()])),
  email: z.optional(z.union([z.string().email(), z.null()])),
  phone: z.optional(z.union([z.string().min(1), z.null()])),
});

export type UpdatePartnerDto = z.infer<typeof UpdatePartnerDto>;

//partner params for detail
export const PartnerDetailParamsDto = z.object({
  id: z.string().uuid(),
});

export type PartnerDetailParamsDto = z.infer<typeof PartnerDetailParamsDto>;

//partner query string
export const PartnerQuerystringDto = z.object({
  generic: z.optional(z.string()),
  organizationId: z.optional(z.string().uuid()),
  sortBy: z.optional(z.string()),
});

export type PartnerQuerystringDto = z.infer<typeof PartnerQuerystringDto>;

//create partner-company
export const CreatePartnerCompanyDto = z.object({
  id: z.string().uuid(),
});

export type CreatePartnerCompanyDto = z.infer<typeof CreatePartnerCompanyDto>;
