import { PaginationQuery } from "services/common/models/pagination";
import { saasApiClient } from "services/http/saas-api-client";

import { TagTypeFiltersDto, TagTypesDto } from "./model";

const entityPath = "/tag-types";

const list = (
  filters?: TagTypeFiltersDto,
  pagination?: PaginationQuery,
): Promise<TagTypesDto> =>
  saasApiClient.getPaginated(
    `${entityPath}/`,
    TagTypesDto,
    pagination,
    filters,
  );

export const tagTypeHttpClient = {
  list,
};
