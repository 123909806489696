import { createContext, useCallback, useContext } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { createLogger } from "@/libs";

type LoadingState = {
  isLoading: boolean;
  isAsyncLoading: boolean;
  isBackgroundLoading: boolean;
  loadingKeys: Set<LoaderKey>;
  showLoader: (key: LoaderKey, ...moreKeys: LoaderKey[]) => void;
  hideLoader: (key: LoaderKey) => void;
  showAsyncLoader: () => void;
  hideAsyncLoader: () => void;
  showBackgroundLoader: () => void;
  hideBackgroundLoader: () => void;
};

const logger = createLogger("useLoading");

export const useLoading = create<LoadingState>()(
  devtools(
    (set) => ({
      loadingKeys: new Set<LoaderKey>(),
      isLoading: false,
      isAsyncLoading: false,
      isBackgroundLoading: false,
      showLoader: (key: LoaderKey, ...moreKeys: LoaderKey[]) => {
        set(({ loadingKeys }) => {
          loadingKeys.add(key);
          moreKeys?.forEach((k) => loadingKeys.add(k));
          logger.debug("showLoader", key, moreKeys, loadingKeys);
          return {
            isLoading: true,
            loadingKeys,
          };
        });
      },
      hideLoader: (key: LoaderKey) => {
        set(({ loadingKeys }) => {
          loadingKeys.delete(key);
          logger.debug("hideLoader", key, loadingKeys);
          return {
            isLoading: loadingKeys.size > 0,
            loadingKeys,
          };
        });
      },
      showAsyncLoader: () => {
        set(() => ({ isAsyncLoading: true }));
      },
      hideAsyncLoader: () => {
        set(() => ({ isAsyncLoading: false }));
      },
      showBackgroundLoader: () => {
        set(() => ({ isBackgroundLoading: true }));
      },
      hideBackgroundLoader: () => {
        set(() => ({ isBackgroundLoading: false }));
      },
    }),
    {
      name: "loading",
    },
  ),
);

type LoaderKey =
  | "defaultKey"
  | "options"
  | "orgDropdown"
  | "orgLoadingKey"
  | "orgListLoadingKey"
  | "orgAdminLoadingKey"
  | "companyListLoadingKey"
  | "companyLoadingKey"
  | "partnerLoadingKey"
  | "sku3DFile"
  | "partnerListLoadingKey"
  | "partnerAdminLoadingKey"
  | "userAdminLoadingKey"
  | "userLoadingKey"
  | "authGuardGetUser"
  | "loadUserOrg"
  | "updateUserOrg"
  | "partnerCompanyAddRemoveLoadingKey"
  | "partnerCompanyLoadingKey"
  | "loadUserCompany"
  | "authUser"
  | "saveUserProfile"
  | "orgAdminRemovalLoadingKey"
  | "uploadCSV"
  | "skuDetail"
  | "skuImages"
  | "userFormCompany"
  | "referencesDownload"
  | "availableOrganizations"
  | "galleryAction"
  | "requestsList"
  | "tagsModalLoading"
  | "viewerSaveConfig"
  | "AddOnsActivate"
  | "TagsListingLoading";

/**
 * DEPRECATION WARNING: Everything below this line is deprecated and will be removed in the future.
 */

type LoadingStateDeprecated = {
  isLoading: boolean;
  isAsyncLoading: boolean;
  /** @deprecated: use zustand instead! const showLoader = useLoading((s) => s.showLoader);*/
  showLoader: (key?: LoaderKey) => void;
  /** @deprecated: use zustand instead! const hideLoader = useLoading((s) => s.hideLoader);*/
  hideLoader: (key?: LoaderKey) => void;
  /** @deprecated: use zustand instead! const showAsyncLoader = useLoading((s) => s.showAsyncLoader);*/
  showAsyncLoader: () => void;
  /** @deprecated: use zustand instead! const hideAsyncLoader = useLoading((s) => s.hideAsyncLoader);*/
  hideAsyncLoader: () => void;
};

const initialState: LoadingStateDeprecated = {
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showLoader: (key?: LoaderKey) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hideLoader: (key?: LoaderKey) => {},
  isAsyncLoading: false,
  showAsyncLoader: () => {},
  hideAsyncLoader: () => {},
};

/* eslint-disable react-hooks/exhaustive-deps */
export function LoadingProvider({ children }: React.PropsWithChildren) {
  const showLoaderNew = useLoading((s) => s.showLoader);
  const hideLoaderNew = useLoading((s) => s.hideLoader);
  const { isLoading, isAsyncLoading } = useLoading((s) => ({
    isLoading: s.isLoading,
    isAsyncLoading: s.isAsyncLoading,
    loadingKeys: s.loadingKeys,
  }));
  const showAsyncLoaderNew = useLoading((s) => s.showAsyncLoader);
  const hideAsyncLoaderNew = useLoading((s) => s.hideAsyncLoader);

  const showLoader = useCallback(
    (key?: LoaderKey) => showLoaderNew(key ?? "defaultKey"),
    [],
  );
  const hideLoader = useCallback(
    (key?: LoaderKey) => hideLoaderNew(key ?? "defaultKey"),
    [],
  );
  const showAsyncLoader = useCallback(showAsyncLoaderNew, []);
  const hideAsyncLoader = useCallback(hideAsyncLoaderNew, []);

  return (
    <GlobalLoadingContext.Provider
      value={{
        isLoading: isLoading,
        showLoader,
        hideLoader,
        isAsyncLoading,
        showAsyncLoader,
        hideAsyncLoader,
      }}
    >
      {children}
    </GlobalLoadingContext.Provider>
  );
}

const GlobalLoadingContext = createContext(initialState);

/** @deprecated use useLoading instead!  */
export const useGlobalLoadingContext = () => useContext(GlobalLoadingContext);
