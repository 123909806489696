import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const CompanyPostBody = z.object({
  fileName: z.string(),
});

export const CompanyImageUploadDto = z.object({
  id: z.string().uuid(),
  fileName: z.string(),
  uploaded: z.boolean(),
  url: z.string(),
});

export const CompanyFiltersDto = z.object({
  generic: z.string().optional(),
  organizationId: z.string().uuid().optional(),
  partnerId: z.string().uuid().optional(),
  sortBy: z.string().optional(),
});

export const CompanyType = z.union([
  z.literal("PROSPECT"),
  z.literal("CUSTOMER"),
]);

export const CompanyDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  vat: z.string().optional(),
  fiscalCode: z.string().optional(),
  country: z.string().optional(),
  province: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  street: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  organizationId: z.string().uuid(),
  organizationName: z.string(),
  industryId: z.string().uuid(),
  industryName: z.string(),
  revenueId: z.string().uuid(),
  revenueFrom: z.number(),
  revenueTo: z.number().optional(),
  revenueCurrency: z.string(),
  userCount: z.number().optional(),
  brandCount: z.number().optional(),
  horizontalLogoUrl: z.string().optional(),
  squareLogoUrl: z.string().optional(),
  usageType: CompanyType.optional(),
  externalCode: z.string().optional(),
});

export const UpdateCompanyDto = z.object({
  name: z.string().min(1).optional(),
  vat: z.string().optional(),
  fiscalCode: z.string().optional(),
  country: z.string().optional(),
  province: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  street: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  organizationId: z.string().uuid().optional(),
  industryId: z.string().uuid().optional(),
  revenueId: z.string().uuid().optional(),
  hasHorizontalLogo: z.boolean().optional(),
  hasSquareLogo: z.boolean().optional(),
});

export const CreateCompanyDto = z.object({
  name: z.string(),
  vat: z.string().optional(),
  fiscalCode: z.string().optional(),
  country: z.string().optional(),
  province: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  street: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  organizationId: z.string().uuid(),
  industryId: z.string().uuid(),
  revenueId: z.string().uuid(),
  hasHorizontalLogo: z.boolean().optional(),
  hasSquareLogo: z.boolean().optional(),
});

export const CompaniesDto = PaginationResponseBuilder(CompanyDto);

export type CompanyFiltersDto = z.infer<typeof CompanyFiltersDto>;
export type CompanyDto = z.infer<typeof CompanyDto>;
export type CompaniesDto = z.infer<typeof CompaniesDto>;
export type CreateCompanyDto = z.infer<typeof CreateCompanyDto>;
export type UpdateCompanyDto = z.infer<typeof UpdateCompanyDto>;
export type CompanyImageUploadDto = z.infer<typeof CompanyImageUploadDto>;
export type CompanyPostBody = z.infer<typeof CompanyPostBody>;
export type CompanyType = z.infer<typeof CompanyType>;

export const CompanyPartnerFiltersDto = z.object({
  generic: z.string().optional(),
  organizationId: z.string().uuid().optional(),
});

export type CompanyPartnerFiltersDto = z.infer<typeof CompanyPartnerFiltersDto>;
