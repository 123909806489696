import classNames from "classnames";

export type InputWrapperProps = {
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  marginReset?: boolean;
  labelClassName?: string;
  required?: boolean;
  htmlFor?: string;
};

const InputWrapper: React.FC<
  InputWrapperProps & { children?: React.ReactNode; className?: string }
> = ({
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  htmlFor,
  children,
  className,
}) => {
  return (
    <div
      id="ad4068f0d304a56"
      className={classNames("input-wrapper", className, {
        "has-no-margin": marginReset,
      })}
    >
      {label && (
        <label htmlFor={htmlFor} className={labelClassName}>
          {label} {required && <span className="is-required">*</span>}
        </label>
      )}
      {children}
      {description && <div className="description">{description}</div>}
    </div>
  );
};

export default InputWrapper;
