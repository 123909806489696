import { useSelector } from "@xstate/react";

import useAuth from "@/hooks/auth/useAuth.ts";
import { UserDto } from "@/services";

const useUser = (): UserDto => {
  const actorRef = useAuth();
  return useSelector(actorRef, (state) => state.context.user)!;
};

export default useUser;
