export const getCompanyId = (): string | null =>
  localStorage.getItem("company-id");

export const setCompanyId = (companyId?: string | null): void => {
  if (!companyId) {
    return localStorage.removeItem("company-id");
  }

  localStorage.setItem("company-id", companyId);
};

export const getOrganizationId = (): string | null =>
  localStorage.getItem("organization-id");

export const setOrganizationId = (organizationId?: string | null): void => {
  if (!organizationId) {
    return localStorage.removeItem("organization-id");
  }

  localStorage.setItem("organization-id", organizationId);
};
