import classNames from "classnames";
import InputWrapper, {
  InputWrapperProps,
} from "components/Input/components/InputWrapper.tsx";
import { SyntheticEvent, useEffect, useRef, useState } from "react";

import { InputSize } from "@/@types";

type RichtextProps = InputWrapperProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> & {
    size?: InputSize;
    error?: boolean;
    onChange?: (value: string) => void;
    rows: number;
  };

const Richtext: React.FC<RichtextProps> = ({
  // WRAPPER
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  htmlFor,

  // INPUT
  size = "standard",
  error = false,
  value,
  defaultValue,
  onChange,
  rows = 3,
  disabled,
  placeholder,
  ...props
}) => {
  const quoteRef = useRef<HTMLQuoteElement>(null);
  const wrapperProps = {
    label,
    description,
    marginReset,
    labelClassName,
    required,
    htmlFor: htmlFor ?? props.id,
  };

  const [_value, _setValue] = useState<RichtextProps["value"]>(
    value ?? defaultValue,
  );

  const onChangeQuote = (ev: SyntheticEvent<HTMLQuoteElement>) => {
    const newValue = (ev.target as HTMLQuoteElement).innerHTML;
    _setValue(newValue);
    onChange?.(newValue);
  };

  useEffect(() => {
    if (quoteRef.current) {
      const html = (value ?? defaultValue) as string;
      quoteRef.current.innerHTML = html.length > 0 ? html : placeholder ?? "";
      _setValue(((value ?? defaultValue) as string) ?? "");
    }
  }, [value, defaultValue]);

  return (
    <InputWrapper {...wrapperProps}>
      <blockquote
        ref={quoteRef}
        onInput={onChangeQuote}
        style={{ height: `calc(1rem * ${rows})` }}
        className={classNames({
          "is-small": size === "small",
          "is-wide": size === "wide",
          "has-error": error,
          disabled: disabled,
        })}
        contentEditable={!disabled}
      />
      <input {...props} value={_value} type="hidden" />
    </InputWrapper>
  );
};

export default Richtext;
