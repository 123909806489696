import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

//login zakeke
export const LoginZakekeReqDto = z.object({
  zakekeToken: z.string(),
});

export const PermissionsZakekeReqDto = z.object({
  zakekeToken: z.string(),
});

export const ZakekeAddonDto = z.object({
  name: z.string(),
  status: z.boolean(),
  featuresAvailable: z.record(z.string(), z.boolean()).optional().nullable(),
  upgradeUrl: z.string().optional(),
});

export const ZakekePermissions = z.optional(
  z.object({
    maxProducts: z.optional(z.number()).nullable(),
    canAddProducts: z.optional(z.boolean()).nullable(),
    numProductsPublished: z.optional(z.number()).nullable(),
    upgradeUrl: z.optional(z.string()).nullable(),
    publishedProductsIds: z
      .optional(
        z.array(
          z.object({
            zakekeProductID: z.number(),
            damProductID: z.string(),
          }),
        ),
      )
      .nullable(),
  }),
);

export const ZakekePermissionsDto = z.optional(
  z.object({
    permissions: ZakekePermissions,
    addOns: z.optional(z.array(ZakekeAddonDto)),
  }),
);

export const LoginZakekeResDto = z.object({
  userId: z.string().uuid(),
  idToken: z.string(),
  accessToken: z.string(),
  refreshToken: z.string(),
  zakekePermissions: ZakekePermissionsDto,
});

export const PermissionsZakekeResDto = z.object({
  zakekePermissions: ZakekePermissionsDto,
});

export type ZakekePermissions = z.infer<typeof ZakekePermissions>;
export type ZakekeAddonDto = z.infer<typeof ZakekeAddonDto>;
export type LoginZakekeReqDto = z.infer<typeof LoginZakekeReqDto>;
export type LoginZakekeResDto = z.infer<typeof LoginZakekeResDto>;
export type ZakekePermissionsDto = z.infer<typeof ZakekePermissionsDto>;
export type PermissionsZakekeResDto = z.infer<typeof PermissionsZakekeResDto>;
export type PermissionsZakekeReqDto = z.infer<typeof PermissionsZakekeReqDto>;

// zakeke addon activation

export type AddOnFeatures = z.infer<typeof AddOnFeatures>;

const AddOnFeatures = z.object({
  virtualPhotographer: z.boolean(),
  pinpoint: z.boolean(),
  animations: z.boolean(),
  analytics3D: z.boolean(),
  multiBrand: z.boolean(),
  autoGeneratingVideoGIF: z.boolean(),
});

export const ActivateZakekeAddonReqDto = z.object({
  zakekeApiToken: z.string(),
  zakekeAddOnName: z.string(),
});

export type ActivateZakekeAddonReqDto = z.infer<
  typeof ActivateZakekeAddonReqDto
>;

export const ActivateZakekeAddonResDto = ZakekePermissionsDto;

export type ActivateZakekeAddonResDto = z.infer<
  typeof ActivateZakekeAddonResDto
>;

const UserGroupCode = z.union([
  z.literal("ffsuperadmin"),
  z.literal("ffadmin"),
  z.literal("orgadmin"),
  z.literal("pm"),
  z.literal("artist3d"),
  z.literal("customer"),
  z.literal("customerview"),
  z.literal("prospect"),
]);
export type UserGroupCode = z.infer<typeof UserGroupCode>;

const UserLanguage = z.union([z.literal("it"), z.literal("en")]);

export const CreateUserDto = z.object({
  email: z.string().email(),
  groupCode: UserGroupCode,
  name: z.string().min(1),
  surname: z.string().min(1),
  phone: z.optional(z.string()),
  jobRole: z.optional(z.string()),
  jobTitleId: z.optional(z.string().uuid()),
  organizationId: z.optional(z.string().uuid()),
  companyId: z.optional(z.string().uuid()),
  partnerId: z.optional(z.string().uuid()),
  zipPermission: z.optional(z.boolean()),
  language: UserLanguage,
});

export const UserDto = z.object({
  id: z.string().uuid(),
  email: z.string().email(),
  groupId: z.string().uuid(),
  groupCode: UserGroupCode,
  groupName: z.string(),
  name: z.optional(z.string()),
  surname: z.optional(z.string()),
  phone: z.optional(z.string()),
  jobRole: z.optional(z.string()),
  jobTitleId: z.optional(z.string().uuid()),
  jobTitleName: z.optional(z.string()),
  organizationId: z.optional(z.string().uuid()),
  organizationName: z.optional(z.string()),
  partnerId: z.optional(z.string().uuid()),
  partnerName: z.optional(z.string()),
  companyId: z.optional(z.string().uuid()).nullable(),
  companyName: z.optional(z.string()).nullable(),
  language: UserLanguage,
  zipPermission: z.boolean(),
  creationDate: z.string(),
});

export type CreateUserDto = z.infer<typeof CreateUserDto>;
export type UserDto = z.infer<typeof UserDto>;
export const UsersDto = PaginationResponseBuilder(UserDto);
export type UsersDto = z.infer<typeof UsersDto>;

export const UpdateUserDto = z.object({
  phone: z.optional(z.string()),
  name: z.optional(z.string()),
  surname: z.optional(z.string()),
  jobRole: z.optional(z.string()),
  jobTitleId: z.optional(z.string().uuid()),
  organizationId: z.optional(z.string().uuid()).nullable(),
  companyId: z.optional(z.string().uuid()).nullable(),
  partnerId: z.optional(z.string().uuid()).nullable(),
  groupCode: z.optional(UserGroupCode),
  zipPermission: z.optional(z.boolean()),
});

export type UpdateUserDto = z.infer<typeof UpdateUserDto>;

export const UserFilters = z.object({
  generic: z.optional(z.string()),
  groupCodes: z.optional(z.array(UserGroupCode)),
  companyId: z.optional(z.string().uuid()),
  organizationId: z.optional(z.string().uuid()),
  partnerOrganizationId: z.optional(z.string().uuid()),
  companyOrganizationId: z.optional(z.string().uuid()),
  partnerId: z.optional(z.string().uuid()),
  sortBy: z.optional(z.string()),
});

export type UserFilters = z.infer<typeof UserFilters>;
