import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  app: {
    font: "Inter, sans-serif",
    primary: "#455A64",
    primary_dark: "#263238",
    primary_light: "#cfd8dc",
    secondary: "#F46200",
    secondary_alt: "#0288D1",
    backgroundColor: "#f6f8f9",
    backgroundColorBase: "#ffffff",
    borderColor: "#DBE2E6",
    secondary_dark: "#da2f8a",
    backgroundColor_dark: "#37474f",
    highlighted: "#99CF16",
    backgroundToolbar: "#eceff1",
    textColor: "#263238",
    disabledOpacity: "0.7",
    component: {
      tabs: {
        textColor: "#445A64",
        fontSize: "14px",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        padding: "16px 20px",
        borderBottomColor: "rgba(219, 226, 230, 1)",
        hover: {
          backgroundColor: "#F6F8F9",
        },
        active: {
          textColor: "rgba(222, 89, 0, 1)",
          borderBottomColor: "rgba(244, 98, 0, 1)",
          backgroundColor: "#FEF2ED",
        },
      },
      accordion: {
        titleColor: "#000",
        lineColor: "#8FA4AE",
        textItemColor: "#445A64",
      },
      pageTitle: {
        size: "24px",
        textColor: "#263238",
        weight: "700",
      },
      pageHeader: {
        padding: "20px 0 40px",
        lineHeight: "1.2",
        title: {
          size: "24px",
          weight: "700",
        },
        description: {
          size: "14px",
        },
      },
      card: {
        borderRadius: "8px",
        title: {
          color: "#000000",
          weight: "700",
          size: "16px",
        },
        text: {
          color: "#000000",
          weight: "400",
          size: "14px",
        },
      },
      link: {
        color: "#0288D1",
        decoration: "underline",
      },
      icon: {
        button: {
          default: {
            color: "#445A64",
            width: "26px",
            height: "26px",
          },
          hover: {
            color: "#000000",
            width: "26px",
            height: "26px",
          },
        },
        info: {
          default: {
            color: "#445A64",
            width: "26px",
            height: "26px",
          },
          hover: {
            color: "#000000",
            width: "26px",
            height: "26px",
          },
        },
        help: {
          color: "#E86825",
          width: "16px",
          height: "16px",
        },
      },
      message: {
        backgroundColor: "#FFE1BE",
        padding: "10px 20px",
        borderRadius: "5px",
        fontWeight: "500",
        fontSize: "12px",
        warning: {
          backgroundColor: "#F8F4c4",
          color: "#906b27",
        },
        error: {
          backgroundColor: "#FEE0E0",
          color: "#d9333b",
        },
      },
      notificationBanner: {
        danger: {
          backgroundColor: "rgba(222,89,0,0.1)",
          iconContainerBGColor: "#DE1C22",
        },
        warning: {
          backgroundColor: "rgba(232,104,37,0.1)",
          iconContainerBGColor: "#E86825",
        },
        information: {
          backgroundColor: "rgba(0,105,211,0.1)",
          iconContainerBGColor: "#0069D3",
        },
        success: {
          backgroundColor: "rgba(0,133,86,0.1)",
          iconContainerBGColor: "#008556",
        },
        extra: {
          backgroundColor: "rgba(240,4,127,0.1)",
          iconContainerBGColor: "#F0047F",
        },
      },

      checkbox: {
        borderRadius: "4px",
        width: "20px",
        height: "20px",
        textWeight: "400",
        textSize: "14px",
        unselected: {
          active: {
            background: "#FFFFFF",
            border: "#8FA4AE",
          },
          disabled: {
            background: "#BDC1CC",
            border: "#D2D5D8",
          },
        },
        selected: {
          active: {
            background: "#F46200",
            border: "#F46200",
            inputColor: "#F46200",
          },
          disabled: {
            background: "#BDC1CC",
            border: "#BDC1CC",
            inputColor: "#BDC1CC",
          },
        },
      },
      switch: {
        borderRadius: "12px",
        width: "40px",
        height: "20px",
        default: {
          backgroundColor: "#DBE2E6",
          textWeight: "400",
          textSize: "14px",
        },
        selected: {
          backgroundColor: "#F46200",
          textWeight: "400",
          textSize: "14px",
        },
      },
      radio: {
        borderRadius: "100%",
        width: "16px",
        height: "16px",
        textWeight: "400",
        textSize: "14px",
        unselected: {
          active: {
            background: "#FFFFFF",
            border: "2px solid #D2D5D8",
          },
          disabled: {
            background: "#BDC1CC",
            border: "2px solid #D2D5D8",
          },
        },
        selected: {
          active: {
            background: "#FFFFFF",
            border: "2px solid #F46200",
            inputColor: "#F46200",
          },
          disabled: {
            background: "#FFFFFF",
            border: "2px solid #BDC1CC",
            inputColor: "#BDC1CC",
          },
        },
      },
      button: {
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: "700",
        large: {
          width: "144px",
          height: "48px",
          padding: "14px 45px",
        },
        medium: {
          width: "100px",
          height: "40px",
          padding: "10px 32px",
        },
        small: {
          width: "80px",
          height: "32px",
          padding: "6px 24px",
        },
        primary: {
          border: "1px solid #F46200",
          default: {
            backgroundColor: "#F46200",
            textColor: "#FFFFFF",
          },
          hover: {
            backgroundColor: "#FF812C",
            textColor: "#FFFFFF",
          },
          active: {
            backgroundColor: "#DE5900",
            textColor: "#FFFFFF",
          },
          disabled: {
            opacity: 0.5,
            backgroundColor: "#F46200",
            textColor: "#FFFFFF",
            border: "1px solid #F46200",
          },
        },
        basic: {
          border: "1px solid #263238",
          default: {
            backgroundColor: "#FFFFFF",
            textColor: "#000000",
          },
          hover: {
            backgroundColor: "#000000",
            textColor: "#FFFFFF",
          },
          active: {
            backgroundColor: "#2D2D2D",
            textColor: "#FFFFFF",
          },
          disabled: {
            opacity: 0.5,
            backgroundColor: "#FFFFFF",
            textColor: "#000000",
            border: "1px solid #263238",
          },
        },
        destructive: {
          border: "1px solid #FD5749",
          default: {
            backgroundColor: "#DE1C22",
            textColor: "#FFFFFF",
          },
          hover: {
            backgroundColor: "#BC2200",
            textColor: "#FFFFFF",
          },
          active: {
            backgroundColor: "#6C0F00",
            textColor: "#FFFFFF",
          },
          disabled: {
            opacity: 0.5,
            backgroundColor: "#DE1C22",
            textColor: "#FFFFFF",
            border: "1px solid #FD5749",
          },
        },
        disabled: {
          border: "1px solid #DDD",
          backgroundColor: "#DDD",
          textColor: "#FFFFFF",
        },
      },
      textButton: {
        fontWeight: 700,
        fontSize: "14px",
        iconWidth: "15px",
        iconHeight: "15px",
        primary: {
          default: {
            color: "#F46200",
          },
          hover: {
            color: "#FF812C",
          },
          active: {
            color: "#DE5900",
          },
        },
        destructive: {
          default: {
            color: "#DE1C22",
          },
          hover: {
            color: "#CD290C",
          },
          active: {
            color: "#670F03",
          },
        },
      },
      pageBlocksContainer: {
        pageBlock: {
          backgroundColor: "#fff",
          border: "1px #DDD solid",
          borderRadius: "6px",
          padding: "20px",
          height: "200px",
          pageBlockTitle: {
            color: "#000000",
            gap: "10px",
            fontSize: "16px",
            fontWeight: 600,
          },
          pageBlockDescription: {
            fontSize: "12px",
          },
          pageBlockButton: {
            padding: "6px 12px",
            fontSize: "14px",
          },
          pageBlockLearnMore: {
            color: "#F46200",
            fontSize: "14px",
            fontWeight: 700,
          },
        },
      },
    },
  },
  input: {
    textSize: "14px",
    color: "#445A64",
  },
  text: {
    boldWeight: "600",
  },
  button: {
    color: "#F46200",
    textColor: "#FFFFFF",
  },
  skeleton: {
    backgroundColor: "#DFDFDF",
    foregroundColor: "#ecebeb",
  },
  size: {
    container: "1232px",
  },
  font: {
    regular: 400,
    medium: 500,
    semi_bold: 600,
    bold: 700,
    extra_bold: 800,
    black: 900,
  },
};

export default theme;
