import { fromPromise } from "xstate";

import { OrganizationsDto, organizationHttpClient } from "@/services";

export type ListOrganizationsInput = {
  size: number;
  page: number;
  partnerId: string | null;
};

export const listOrganizations = fromPromise<
  OrganizationsDto,
  ListOrganizationsInput
>(async ({ input: { size, page, partnerId } }) => {
  return await organizationHttpClient.list(
    {
      partnerId: partnerId ?? undefined,
      sortBy: "name.asc",
    },
    {
      offset: (page - 1) * size,
      limit: size,
    },
  );
});
