import { ZTooltip } from "@zakeke/zakeke-ui-widgets";

import { ReactComponent as TooltipIcon } from "@/svg/tooltip-icon.svg?react";

export function Tooltip({
  text,
  position = "top",
  icon,
  disabled = false,
}: {
  text: string;
  position?: "top" | "bottom" | "left" | "right";
  icon?: React.ReactNode;
  disabled?: boolean;
}) {
  if (disabled) {
    return icon ? icon : <TooltipIcon />;
  }

  return (
    <ZTooltip position={position} title={text}>
      {icon ? (
        icon
      ) : (
        <span>
          <TooltipIcon />
        </span>
      )}
    </ZTooltip>
  );
}
