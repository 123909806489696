import {
  SkuNoteActor,
  SkuProjectType,
  SkuType,
} from "services/http/sku/models";
import { z } from "zod";

import { StatusCode } from "../status";

export const AnalyticsTokenDto = z.object({
  token: z.string(),
  customerCode: z.string(),
});

export type AnalyticsTokenDto = z.infer<typeof AnalyticsTokenDto>;

export const SkuCSVBulkUploadDTO = z.object({
  skus: z.array(
    z.object({
      productId: z.string(),
      description: z.optional(z.string()),
      width: z.optional(z.number()),
      height: z.optional(z.number()),
      length: z.optional(z.number()),
    }),
  ),
  categoryId: z.string().uuid(),
  brandId: z.string().uuid(),
  projectType: SkuProjectType,
});

export const SkuErrorDto = z.object({
  productId: z.string(),
  type: z.string(),
});

export const SkuCSVBulkUploadResponseDTO = z.object({
  added: z.number(),
});

export type SkuCSVBulkUploadDTO = z.infer<typeof SkuCSVBulkUploadDTO>;
export type SkuCSVBulkUploadResponseDTO = z.infer<
  typeof SkuCSVBulkUploadResponseDTO
>;

export const SkuBulkReadRequestDto = z.object({
  brandId: z.string().uuid(),
  productIds: z.array(z.string()),
});

export type SkuBulkReadRequestDto = z.infer<typeof SkuBulkReadRequestDto>;

export const SkuBulkReadData = z.object({
  id: z.string().uuid(),
  productId: z.string(),
  statusCode: StatusCode,
});

export type SkuBulkReadData = z.infer<typeof SkuBulkReadData>;

export const SkuBulkReadResponseDto = z.array(SkuBulkReadData);

export type SkuBulkReadResponseDto = z.infer<typeof SkuBulkReadResponseDto>;

export const SkuStatusOptionsReqDto = z.object({
  id: z.string().uuid(),
});

export type SkuStatusOptionsReqDto = z.infer<typeof SkuStatusOptionsReqDto>;

export const SkuStatusOptions = z.object({
  next: z.string().optional(),
  nextGroupCode: z.string().optional(),
  reject: z.string().optional(),
  rejectGroupCode: z.string().optional(),
});
export const SkuStatusOptionsResDto = SkuStatusOptions;

export type SkuStatusOptions = z.infer<typeof SkuStatusOptions>;

export type SkuStatusOptionsResDto = z.infer<typeof SkuStatusOptionsResDto>;

// rpc confirm notes
export const ConfirmNotesReqDto = z.object({
  skuId: z.string().uuid(),
  sender: SkuNoteActor,
  receiver: SkuNoteActor,
});

export type ConfirmNotesReqDto = z.infer<typeof ConfirmNotesReqDto>;

export const ConfirmNotesResDto = z.object({
  updated: z.number(),
});

export type ConfirmNotesResDto = z.infer<typeof ConfirmNotesResDto>;

// rpc set notes as received
export const ReceiveNotesReqDto = z.object({
  lastReceivedSkuNoteId: z.string().uuid(),
});

export type ReceiveNotesReqDto = z.infer<typeof ReceiveNotesReqDto>;

export const ReceiveNotesResDto = z.object({
  updated: z.number(),
});

export type ReceiveNotesResDto = z.infer<typeof ReceiveNotesResDto>;

export const SearchItemsReqDto = z.object({
  productId: z.optional(z.string()),
  types: z.optional(z.array(SkuType)),
  categoryIds: z.optional(z.array(z.string().uuid())),
  companyId: z.optional(z.string().uuid()),
  brandIds: z.optional(z.array(z.string().uuid())),
  statusId: z.optional(z.string().uuid()),
  tagIds: z.optional(z.array(z.string().uuid())),
  statusCodes: z.optional(z.array(StatusCode)),
  hasGif: z.boolean().optional(),
  hasVideo: z.boolean().optional(),
  has3dModel: z.boolean().optional(),
  disabled: z.boolean().optional(),
});

export const SearchItem = z.object({
  id: z.string().uuid(),
  name: z.string(),
  skus: z.number(),
});

export const SearchItemsResDto = z.object({
  categories: z.array(SearchItem),
  tagTypes: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
      tags: z.array(SearchItem),
    }),
  ),
  hasGif: z.number(),
  hasVideo: z.number(),
  has3dModel: z.number(),
});

export type SearchItem = z.infer<typeof SearchItem>;
export type SearchItemsReqDto = z.infer<typeof SearchItemsReqDto>;
export type SearchItemsResDto = z.infer<typeof SearchItemsResDto>;

//create gif zip
export const CreateGifZipReqDto = z.object({
  skuIds: z.array(z.string().uuid()),
});

export const CreateGifZipResDto = z.object({
  url: z.string().url(),
});

export type CreateGifZipReqDto = z.infer<typeof CreateGifZipReqDto>;
export type CreateGifZipResDto = z.infer<typeof CreateGifZipResDto>;

//create video zip
export const CreateVideoZipReqDto = z.object({
  skuIds: z.array(z.string().uuid()),
});

export const CreateVideoZipResDto = z.object({
  url: z.string().url(),
});

export type CreateVideoZipReqDto = z.infer<typeof CreateVideoZipReqDto>;
export type CreateVideoZipResDto = z.infer<typeof CreateVideoZipResDto>;

//generate gif video
export const ParamsGenerateGifVideoReq = z.object({
  isGif: z.boolean(),
  isVideo: z.boolean(),
  width: z.number(),
  height: z.number(),
  fps: z.number(),
  duration: z.number(),
  offset: z.number(),
  zoom: z.number(),
  backgroundColor: z.string(),
});

export type ParamsGenerateGifVideoReq = z.infer<
  typeof ParamsGenerateGifVideoReq
>;

export const GenerateGifVideoReqDto = z.object({
  skuIds: z.array(z.string().uuid()),
  params: ParamsGenerateGifVideoReq,
});

export const GenerateGifVideoResDto = z.object({
  skusCount: z.number(),
});

export type GenerateGifVideoReqDto = z.infer<typeof GenerateGifVideoReqDto>;
export type GenerateGifVideoResDto = z.infer<typeof GenerateGifVideoResDto>;

export const UpdateViewerConfigReqDto = z.object({
  sku3dModelId: z.string().uuid(),
  viewerConfigFileName: z.string(),
  viewerConfig: z.any(), // we are not going to map the viewer config here
  arIcon: z.string().nullable().optional(),
});

export const UpdateViewerConfigResDto = z.object({
  updated: z.boolean(),
  viewerConfig: z.any(),
});

export type UpdateViewerConfigReqDto = z.infer<typeof UpdateViewerConfigReqDto>;
export type UpdateViewerConfigResDto = z.infer<typeof UpdateViewerConfigResDto>;
