import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";
import { SkuType } from "../sku/models";

export const CategoryDto = z.object({
  id: z.string(),
  name: z.string(),
  defaultIndustryIds: z.array(z.string().uuid()),
});

export const CategoriesDto = PaginationResponseBuilder(CategoryDto);

export const CategoryParamsDto = z.object({
  skuTypes: z.array(SkuType),
});

export type CategoryDto = z.infer<typeof CategoryDto>;
export type CategoriesDto = z.infer<typeof CategoriesDto>;
export type CategoryParamsDto = z.infer<typeof CategoryParamsDto>;
