class BaseApplicationError extends Error {
  constructor(
    public readonly code: ErrorCode,
    message: string,
    public readonly customCode?: string,
  ) {
    super(message);
    this.name = this.constructor.name;
    // Cleaner stack trace for v8
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

const ERROR_CODE = Object.freeze({
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  DUPLICATE_ENTITY_SERVER_ERROR: "DUPLICATE_ENTITY_SERVER_ERROR",
  NOT_FOUND: "NOT_FOUND",
  VALIDATION_ERROR: "VALIDATION_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  FORBIDDEN: "FORBIDDEN",
  BAD_REQUEST: "BAD_REQUEST",
  ABORT_REQUEST: "ABORT_REQUEST",
});

type ErrorCode = (typeof ERROR_CODE)[keyof typeof ERROR_CODE];

class ApplicationExtendedError extends BaseApplicationError {
  public originalError?: Error | unknown;

  constructor(
    message: string,
    error: undefined | Error | unknown,
    code: ErrorCode,
    customCode?: string,
  ) {
    super(code, message, customCode);
    this.originalError = error;
    const message_lines = (this.message.match(/\n/g) || []).length + 1;
    this.stack = this.stack
      ?.split("\n")
      .slice(0, message_lines + 1)
      .join("\n");
    if (error && error instanceof Error) this.stack += `\n${error.stack}`;
  }
}

export class ValidationError extends ApplicationExtendedError {
  constructor(message: string, error?: Error | unknown) {
    super(message, error, ERROR_CODE.VALIDATION_ERROR);
  }
}
export class HttpUnauthorizedError extends ApplicationExtendedError {
  constructor(message: string, error?: Error | unknown) {
    super(message, error, ERROR_CODE.UNAUTHORIZED);
  }
}
export class HttpForbiddenError extends ApplicationExtendedError {
  constructor(message: string, customCode?: string, error?: Error | unknown) {
    super(message, error, ERROR_CODE.FORBIDDEN, customCode);
  }
}
export class HttpBadRequestError extends ApplicationExtendedError {
  constructor(message: string, customCode?: string, error?: Error | unknown) {
    super(message, error, ERROR_CODE.BAD_REQUEST, customCode);
  }
}
export class HttpInternalServerError extends ApplicationExtendedError {
  constructor(message: string, error?: Error | unknown) {
    super(message, error, ERROR_CODE.INTERNAL_SERVER_ERROR);
  }
}
export class UnhandledError extends ApplicationExtendedError {}

export class HttpAbortError extends ApplicationExtendedError {
  constructor(message: string = "Abort Request", error?: Error | unknown) {
    super(message, error, ERROR_CODE.ABORT_REQUEST);
  }
}
