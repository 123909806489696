import { Estimate } from "@futurefashion/dam-api-client";
import { useState } from "react";
import { TagTypeFiltersDto } from "services/http/tagType/model";
import { tagTypeHttpClient } from "services/http/tagType/service";

import { SelectboxOption } from "@/@types";
import apiClient from "@/blackbox/api-client.ts";
import { createLogger, displayUserName } from "@/libs";
import {
  BrandFiltersDto,
  BrandsDto,
  CompanyFiltersDto,
  OrganizationFilters,
  PartnerQuerystringDto,
  StatusGroupsListDto,
  StatusListDto,
  UsersDto,
  brandHttpClient,
  categoriesHttpClient,
  companyHttpClient,
  organizationHttpClient,
  partnerHttpClient,
  statusGroupsHttpClient,
  statusHttpClient,
  tagHttpClient,
  usersHttpClient,
} from "@/services";

import { hasUserGroup, useAuthentication } from "./useAuthentication.hook";

export type OptionToLoad =
  | "categoryTypeId"
  | "tags"
  | "brand"
  | "organization"
  | "company"
  | "partner"
  | "industry"
  | "ownBrand"
  | "status"
  | "artist"
  | "projectManager"
  | "requests";

type UseOptionsParams = {
  optionsToFetch: OptionToLoad[];
};

const logger = createLogger("useOptions.hook");

const estimateClient = new Estimate(apiClient);

export const useOptions = ({ optionsToFetch }: UseOptionsParams) => {
  const { user } = useAuthentication();
  const isCustomer = hasUserGroup(["customer"], user);
  const [isLoading, setIsLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState<SelectboxOption[]>([]);
  const [ownBrandOptions, setOwnBrandOptions] = useState<SelectboxOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<SelectboxOption[]>([]);
  const [artistOptions, setArtistOptions] = useState<SelectboxOption[]>([]);
  const [orderOptions, setOrderOptions] = useState<SelectboxOption[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<
    SelectboxOption[]
  >([]);
  const [companyOptions, setCompanyOptions] = useState<SelectboxOption[]>([]);
  const [partnerOptions, setPartnerOptions] = useState<SelectboxOption[]>([]);
  const [industryOptions, setIndustryOptions] = useState<SelectboxOption[]>([]);
  const [projectManagerOptions, setProjectManagerOptions] = useState<
    SelectboxOption[]
  >([]);
  const [tagsOptions, setTagsOptions] = useState<SelectboxOption[]>([]);
  const [categoryTypeId, setCategoryTypeId] = useState<string | undefined>(
    undefined,
  );

  const getUserList = async (groupCode: "pm" | "artist3d") => {
    try {
      const { result }: UsersDto = await usersHttpClient.list({
        groupCodes: [groupCode],
        partnerId: user?.partnerId ?? undefined,
        partnerOrganizationId: user?.organizationId ?? undefined,
      });
      if (groupCode === "pm")
        setProjectManagerOptions(
          result.map((user) => ({
            value: user.id,
            label: displayUserName(user),
          })),
        );
      if (groupCode === "artist3d")
        setArtistOptions(
          result.map((user) => ({
            value: user.id,
            label: displayUserName(user),
          })),
        );
    } catch (error) {
      logger.error(error as Error, "Error while fetching user list");
    }
  };

  const getBrandList = async (brandFilter?: BrandFiltersDto) => {
    const brands = await getBrandOptions(brandFilter);
    setBrandOptions(brands);
  };

  const getOrganizationList = async () => {
    const organizations = await getOrganizationsOptions();
    setOrganizationOptions(organizations);
  };

  const getPartnerList = async (partnerFilter?: PartnerQuerystringDto) => {
    const partners = await getPartnersOptions(partnerFilter);
    setPartnerOptions(partners);
  };

  const getCompanyList = async (companyFilter?: CompanyFiltersDto) => {
    const companies = await getCompaniesOptions(companyFilter);
    setCompanyOptions(companies);
  };

  const getIndustryList = async () => {
    const industries = await getIndustriesOptions();
    setIndustryOptions(industries);
  };

  const getOwnBrandList = async () => {
    if (!user) return;

    const filters: BrandFiltersDto | undefined = hasUserGroup(
      ["ffadmin", "ffsuperadmin"],
      user,
    )
      ? undefined
      : {
          artist3DId: hasUserGroup(["artist3d"], user) ? user.id : undefined,
          projectManagerId: hasUserGroup(["pm"], user) ? user.id : undefined,
        };

    const filteredBrands = await getBrandOptions(filters);
    setOwnBrandOptions(filteredBrands);
  };

  const getBrandOptions = async (filters?: BrandFiltersDto) => {
    try {
      const { result }: BrandsDto = await brandHttpClient.list(filters);
      return result.map((brand) => ({
        value: brand.id,
        label: brand.name,
      }));
    } catch (error) {
      logger.error(error as Error, "Error while fetching brand list");
    }

    return [];
  };

  const getOrganizationsOptions = async (filters?: OrganizationFilters) => {
    try {
      const { result } = await organizationHttpClient.list(filters);
      return result.map((organization) => ({
        value: organization.id,
        label: organization.name,
      }));
    } catch (error) {
      logger.error(error as Error, "Error while fetching organizations list");
    }

    return [];
  };

  const getCompaniesOptions = async (filters?: CompanyFiltersDto) => {
    try {
      const { result } = await companyHttpClient.list(filters);
      return result.map((company) => ({
        value: company.id,
        label: company.name,
      }));
    } catch (error) {
      logger.error(error as Error, "Error while fetching companies list");
    }

    return [];
  };

  const getPartnersOptions = async (partnerFilter?: PartnerQuerystringDto) => {
    try {
      const { result } = await partnerHttpClient.list(partnerFilter);
      return result.map((company) => ({
        value: company.id,
        label: company.name,
      }));
    } catch (error) {
      logger.error(error as Error, "Error while fetching partners list");
    }

    return [];
  };

  const getIndustriesOptions = async () => {
    try {
      const { result } = await categoriesHttpClient.list();
      return result.map((industry) => ({
        value: industry.id,
        label: industry.name,
      }));
    } catch (error) {
      logger.error(error as Error, "Error while fetching industries list");
    }

    return [];
  };

  const getStatusList = async () => {
    try {
      if (isCustomer) {
        const { result }: StatusGroupsListDto =
          await statusGroupsHttpClient.list();
        setStatusOptions(
          result.map((status) => ({
            value: status.id,
            label: status.name,
          })),
        );
      } else {
        const { result }: StatusListDto = await statusHttpClient.list();
        setStatusOptions(
          result.map((status) => ({
            value: status.id,
            label: status.name,
          })),
        );
      }
    } catch (error) {
      logger.error(error as Error, "Error while fetching status list");
    }
  };

  const getTagsList = async (
    tagTypeName?: string,
    filters?: TagTypeFiltersDto,
  ) => {
    try {
      const tagTypeId = await getCategoryTypeId(tagTypeName);

      const { result: tagsResult } = await tagHttpClient.list({
        typeId: tagTypeId,
        brandId: filters?.brandId,
      });

      setTagsOptions(
        tagsResult.map((t) => ({
          value: t.id,
          label: t.name,
        })),
      );
      setCategoryTypeId(tagTypeId);
    } catch (error) {
      logger.error(error as Error, "Error while fetching tags options list");
    }
  };

  const getCategoryTypeId = async (
    tagTypeName?: string,
  ): Promise<string | undefined> => {
    const _tagTypeName = tagTypeName || "Category";
    try {
      const { result: tagTypesResult } = await tagTypeHttpClient.list();
      const tagTypeId = tagTypesResult.filter((t) => t.name === _tagTypeName)[0]
        ?.id;
      setCategoryTypeId(tagTypeId);

      return tagTypeId;
    } catch (error) {
      logger.error(error as Error, "Error while categoryTypeId options");
    }
  };

  const getOrderOptions = async () => {
    try {
      const resp = await estimateClient
        .list()
        .addSortByFilter(["id.desc"])
        .execute();

      setOrderOptions(
        resp.data?.items?.map((item) => ({
          value: item.id.toString(),
          label: `${item.id.toString().padStart(4, "0")} ${
            item.shortDescription ? ` (${item.shortDescription})` : ""
          }`,
        })),
      );
    } catch (error) {
      logger.error(error as Error, "Error while loading Requests options");
    }

    return [];
  };

  const loadOptions = async (optionsFilters?: {
    brandFilter?: BrandFiltersDto;
    companyFilter?: CompanyFiltersDto;
    partnerFilter?: PartnerQuerystringDto;
    tagsFilter?: TagTypeFiltersDto & { tagTypeName?: string };
  }) => {
    setIsLoading(true);
    try {
      if (optionsToFetch.includes("brand")) {
        await getBrandList(optionsFilters?.brandFilter);
      }
      if (optionsToFetch.includes("ownBrand")) {
        await getOwnBrandList();
      }
      if (optionsToFetch.includes("status")) {
        await getStatusList();
      }
      if (optionsToFetch.includes("artist")) {
        await getUserList("artist3d");
      }
      if (optionsToFetch.includes("projectManager")) {
        await getUserList("pm");
      }
      if (optionsToFetch.includes("organization")) {
        await getOrganizationList();
      }
      if (optionsToFetch.includes("partner")) {
        await getPartnerList(optionsFilters?.partnerFilter);
      }
      if (optionsToFetch.includes("company")) {
        await getCompanyList(optionsFilters?.companyFilter);
      }
      if (optionsToFetch.includes("industry")) {
        await getIndustryList();
      }
      if (optionsToFetch.includes("tags")) {
        await getTagsList(
          optionsFilters?.tagsFilter?.tagTypeName,
          optionsFilters?.tagsFilter,
        );
      }
      if (optionsToFetch.includes("categoryTypeId")) {
        await getCategoryTypeId();
      }
      if (optionsToFetch.includes("requests")) {
        await getOrderOptions();
      }
    } catch (error) {
      logger.error(error as Error, "Error while fetching options");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoadingOptions: isLoading,
    options: {
      brandOptions,
      organizationOptions,
      companyOptions,
      industryOptions,
      ownBrandOptions,
      statusOptions,
      artistOptions,
      projectManagerOptions,
      partnerOptions,
      tagsOptions,
      categoryTypeId,
      orderOptions,
    },
    loadOptions,
  };
};
