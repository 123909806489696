import { PaginationQuery } from "services/common/models/pagination";

import { RequestConfig } from "@/libs";

import { saasApiClient } from "../saas-api-client";
import {
  CreatePartnerCompanyDto,
  CreatePartnerDto,
  PartnerDto,
  PartnerQuerystringDto,
  PartnersDto,
  UpdatePartnerDto,
} from "./models";

const entityPath = "/partners";

const list = (
  filters?: PartnerQuerystringDto,
  pagination?: PaginationQuery,
  config?: RequestConfig,
): Promise<PartnersDto> =>
  saasApiClient.getPaginated(
    `${entityPath}`,
    PartnersDto,
    pagination,
    filters,
    config,
  );

const get = (id: string): Promise<PartnerDto> =>
  saasApiClient.get(`${entityPath}/${id}`, PartnerDto);

const create = (partner: CreatePartnerDto): Promise<PartnerDto> =>
  saasApiClient.post(`${entityPath}`, partner, PartnerDto);

const update = (id: string, partner: UpdatePartnerDto): Promise<PartnerDto> =>
  saasApiClient.patch(`${entityPath}/${id}`, partner, PartnerDto);

const remove = (id: string): Promise<void> =>
  saasApiClient.delete(`${entityPath}/${id}`);

const company = {
  add: (partnerId: string, payload: CreatePartnerCompanyDto): Promise<void> =>
    saasApiClient.post(`${entityPath}/${partnerId}/companies`, payload),
  remove: (partnerId: string, companyId: string): Promise<void> =>
    saasApiClient.delete(`${entityPath}/${partnerId}/companies/${companyId}`),
};

export const partnerHttpClient = {
  list,
  get,
  create,
  update,
  remove,
  company,
};
