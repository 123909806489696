import classNames from "classnames";

import { InputSize, SelectboxOption } from "@/@types";

import "./SelectboxField.scss";

export function SelectboxField({
  label,
  inputSize = "standard",
  options = [],
  showError = false,
  marginReset = false,
  description = undefined,
  ...props
}: {
  label?: string | React.ReactNode;
  inputSize?: InputSize;
  options: SelectboxOption[];
  showError?: boolean;
  marginReset?: boolean;
  description?: string | React.ReactNode;
} & React.InputHTMLAttributes<HTMLSelectElement>) {
  const inputClassName = classNames("SelectboxField_input", {
    SelectboxField_input___small: inputSize === "small",
    SelectboxField_input___error: showError,
  }) as string;
  return (
    <div
      className={classNames("SelectboxField", {
        SelectboxField___marginReset: marginReset,
      })}
    >
      {label && <label className="SelectboxField_label">{label}</label>}
      <select {...props} className={inputClassName}>
        {props.placeholder && (
          <option value="" disabled>
            {props.placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {description && (
        <div className="SelectboxField_description">{description}</div>
      )}
    </div>
  );
}
