import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const JobTitleDto = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
});

export const JobTitlesDto = PaginationResponseBuilder(JobTitleDto);

export type JobTitleDto = z.infer<typeof JobTitleDto>;
export type JobTitlesDto = z.infer<typeof JobTitlesDto>;
