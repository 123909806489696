import i18n from "i18next";

import { getAccessToken } from "@/blackbox/storage/auth.ts";
import { ENV, HttpClientBuilder } from "@/libs";

export const saasApiClient = new HttpClientBuilder(`${ENV.VITE_API_BASE_URL}`)
  .addOnBeforeRequest(async (config) => {
    try {
      const jwt = getAccessToken();
      if (!jwt) return config;

      return {
        ...config,
        headers: {
          ...(config.headers || {}),
          Authorization: `Bearer ${jwt}`,
        },
      };
    } catch (error) {
      return config;
    }
  })
  .addOnBeforeRequest(async (config) => {
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        "Content-Language": i18n.language,
      },
    };
  })
  .build();
