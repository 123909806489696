import { saasApiClient } from "services/http/saas-api-client";

import { RequestConfig } from "@/libs";
import { PaginationQuery } from "@/services";

import {
  CreateUserDto,
  UpdateUserDto,
  UserDto,
  UserFilters,
  UsersDto,
} from "./models";

const entityPath = "/users";

const list = (
  filters?: UserFilters,
  pagination?: PaginationQuery,
  config?: RequestConfig,
): Promise<UsersDto> =>
  saasApiClient.getPaginated(
    `${entityPath}`,
    UsersDto,
    pagination,
    filters,
    config,
  );

const create = (user: CreateUserDto): Promise<UserDto> =>
  saasApiClient.post(`${entityPath}`, user, UserDto);

const update = (user: UpdateUserDto): Promise<UserDto> =>
  saasApiClient.patch(`${entityPath}/me`, user, UserDto);

const updateById = (id: string, user: UpdateUserDto): Promise<UserDto> =>
  saasApiClient.patch(`${entityPath}/${id}`, user, UserDto);

const get = (): Promise<UserDto> =>
  saasApiClient.get(`${entityPath}/me`, UserDto);

const getById = (id: string): Promise<UserDto> =>
  saasApiClient.get(`${entityPath}/${id}`, UserDto);

const remove = (id: string): Promise<void> =>
  saasApiClient.delete(`${entityPath}/${id}`);

export const usersHttpClient = {
  get,
  getById,
  list,
  create,
  update,
  updateById,
  remove,
};
