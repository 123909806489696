import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const RevenueDto = z.object({
  id: z.string().min(1),
  from: z.number(),
  to: z.number().optional(),
  currency: z.string(),
});

export const RevenuesDto = PaginationResponseBuilder(RevenueDto);

export type RevenueDto = z.infer<typeof RevenueDto>;
export type RevenuesDto = z.infer<typeof RevenuesDto>;

export const toString = (
  revenue: Pick<RevenueDto, "from" | "to" | "currency"> | RevenueDto,
  numberFormatter: (num: number) => string = (num) => num.toString(),
) => {
  const rightBound = revenue.to ? ` - ${numberFormatter(revenue.to)}` : "+";
  let currencySymbol = "";
  switch (revenue.currency) {
    case "USD":
      currencySymbol = "$";
      break;
    case "EUR":
      currencySymbol = "€";
      break;
    case "GBP":
      currencySymbol = "£";
      break;
  }

  return `${numberFormatter(revenue.from)}${rightBound} ${currencySymbol}`;
};
