import { AuthResponse, ChallengeResponse } from "services/http/auth";
import { AnyActorRef, DoneActorEvent, assign, sendParent, setup } from "xstate";

import { assignError } from "@/blackbox/machines/utils.ts";
import { setAccessToken, setRefreshToken } from "@/blackbox/storage/auth.ts";

import {
  AuthenticationInput,
  ChallengeInput,
  challenge,
  login,
} from "./actors.ts";

type Challenge = {
  session: string;
  newPassword: string | null;
};

type LoginInput = {
  authRef: AnyActorRef;
};

type LoginContext = LoginInput & {
  username: string | null;
  challenge: Challenge | null;
  error: unknown | null;
};

type LoginEvent = {
  type: "login";
  payload: { username: string; password: string };
};

export type ChallengeEvent = {
  type: "challenge";
  payload: { newPassword: string };
};

type LoginEvents = LoginEvent | ChallengeEvent;

const machine = setup({
  types: {
    context: {} as LoginContext,
    events: {} as LoginEvents,
    input: {} as LoginInput,
  },
  actions: {
    // @ts-ignore
    assignError,
    persistTokens: ({ event }) => {
      const output = (event as unknown as DoneActorEvent<AuthResponse>).output;
      setRefreshToken(output.refreshToken);
      setAccessToken(output.token);
    },
    parentVerify: sendParent({ type: "verify" }),
  },
  actors: {
    login: login,
    challenge: challenge,
  },
}).createMachine({
  context: ({ input }) => ({
    ...input,
    username: null,
    challenge: null,
    error: null,
  }),
  id: "login",
  initial: "idle",
  states: {
    idle: {
      id: "idle",
      on: {
        login: {
          guard: ({ context }) => context.challenge === null,
          target: "pending.authenticate",
          actions: assign({
            username: ({ event }) => event.payload.username,
          }),
        },
        challenge: {
          guard: ({ context }) =>
            !!context.challenge && !!context.challenge?.session,
          target: "pending.challenge",
        },
      },
    },
    pending: {
      initial: "authenticate",
      states: {
        authenticate: {
          invoke: {
            src: "login",
            id: "login",
            input: ({ event }): AuthenticationInput => ({
              username: (event as LoginEvent).payload.username,
              password: (event as LoginEvent).payload.password,
            }),
            onDone: [
              {
                guard: ({ event }) => !!(event.output as AuthResponse).token,
                actions: ["persistTokens", "parentVerify"],
                target: "#done",
              },
              {
                guard: ({ event }) =>
                  !!(event.output as ChallengeResponse).type,
                target: "#idle",
                actions: assign({
                  challenge: ({ event }) => ({
                    session: event.output.challengeMetadata!.session,
                    newPassword: null,
                  }),
                }),
              },
            ],
            onError: {
              target: "#idle",
              actions: "assignError",
            },
          },
        },
        challenge: {
          invoke: {
            src: "challenge",
            id: "challenge",
            input: ({ event, context }): ChallengeInput => ({
              username: context.username!,
              newPassword: (event as ChallengeEvent).payload.newPassword,
              session: context.challenge!.session!,
            }),
            onDone: {
              actions: ["persistTokens", "parentVerify"],
              target: "#done",
            },
            onError: {
              target: "#idle",
              actions: "assignError",
            },
          },
        },
      },
    },
    done: { id: "done", type: "final" },
  },
});

export default machine;
