import { Checkbox } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import styled from "styled-components";

import { THeadData } from ".";

const StyledCheckbox = styled(Checkbox)`
  margin-left: 3px;
`;

export function TableRow<T>({
  showCheckbox,
  row,
  isSelected,
  onSelect,
  headData,
  hasError,
}: {
  row: T;
  showCheckbox: boolean;
  isSelected: boolean;
  onSelect: (isSelected: boolean) => void;
  headData: THeadData<T>[];
  hasError?: boolean;
}) {
  return (
    <tr
      className={classNames("Table_tr", {
        Table_tr___selected: isSelected,
        Table_tr___error: hasError,
      })}
    >
      {showCheckbox && (
        <td className="Table_td Table_td___narrow">
          <StyledCheckbox
            checked={isSelected}
            onChange={(val: boolean) => onSelect(val)}
          />
        </td>
      )}
      {headData.map(({ key, customCell }) => (
        <td key={key} className="Table_td">
          {customCell ? customCell(row) : <>{row[key as keyof T]}</>}
        </td>
      ))}
    </tr>
  );
}
