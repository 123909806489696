import { ZakekeLoading } from "@zakeke/zakeke-ui-widgets";
import { useEffect, useRef } from "react";

//import logo3ds from "./logo3ds.svg";

/**
 * @deprecated use FullscreenLoader instead
 */
export function Loader() {
  const ref = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    const { current } = ref;
    current?.showModal();

    return () => {
      current?.close();
    };
  }, []);

  return (
    <dialog className="Loader" ref={ref}>
      <ZakekeLoading />
    </dialog>
  );
}
