import { saasApiClient } from "services/http/saas-api-client";

import { StatusGroupsListDto } from "./models";

const entityPath = "/sku-status-groups";

const list = (): Promise<StatusGroupsListDto> =>
  saasApiClient.getPaginated(`${entityPath}/`, StatusGroupsListDto);

export const statusGroupsHttpClient = {
  list,
};
