import { useMachine, useSelector } from "@xstate/react";
import classNames from "classnames";
import useAuth from "hooks/auth/useAuth.ts";
import useCheckUserGroup from "hooks/auth/useCheckUserGroup.ts";
import { useEffect, useMemo } from "react";

import selectOrgMachine from "@/blackbox/machines/organization/select.ts";
import { SelectboxField } from "@/components";
import logoSquare from "@/components/FutureFashionLogo/logo192.png";
import logo from "@/components/FutureFashionLogo/logo-future-fashion.png";
import { useI18n, useSidebar } from "@/hooks";
import { initials } from "@/libs";

import zakekeLogo from "./../img/logo-350x100px-zakeke.png";
import zakekeLogoSquare from "./../img/zakeke-favicon.png";

function OrganizationSelect() {
  const { t } = useI18n("common");
  const isSidebarOpen = useSidebar((state) => state.isSidebarOpen);

  const authActor = useAuth();
  const checkUserGroup = useCheckUserGroup();
  const { user, organizationId } = useSelector(
    authActor,
    (snap) => snap.context,
  );

  const [state, send] = useMachine(selectOrgMachine, {
    id: "select-organizations",
    input: {
      partnerId: user?.partnerId ?? null,
      selectedOrganizationId: organizationId,
      authActorRef: authActor,
    },
  });

  const canSelectOrganization = checkUserGroup([
    "ffsuperadmin",
    "ffadmin",
    "pm",
    "artist3d",
  ]);

  const organization = useMemo<{ id: string; name: string } | null>(() => {
    if (state.context.organizations.length === 0) {
      return user?.organizationName
        ? { id: user.organizationId ?? "", name: user.organizationName ?? "" }
        : null;
    }

    const org = state.context.organizations.find(
      (org) => org.id === state.context.selectedOrganizationId,
    );

    return org ? { id: org.id, name: org.name } : null;
  }, [user, state]);

  const image = useMemo(() => {
    if (!organization) return null;

    // FIXME: remove this when companies will have a logo
    if (organization.name === "Future Fashion") {
      return isSidebarOpen ? logo : logoSquare;
    }
    if (organization.name === "ZAKEKE") {
      return isSidebarOpen ? zakekeLogo : zakekeLogoSquare;
    }

    return null;
  }, [organization, isSidebarOpen]);

  useEffect(() => {
    if (!user?.organizationId) {
      send({ type: "load" });
    }
  }, [user]);

  if (
    canSelectOrganization &&
    state.context.organizations.length > 1 &&
    isSidebarOpen
  ) {
    return (
      <SelectboxField
        options={state.context.organizations.map((o) => ({
          value: o.id,
          label: o.name,
        }))}
        label={t("appNavigation.organizationSelectLabel")}
        value={state.context.selectedOrganizationId ?? ""}
        onChange={(e) =>
          send({ type: "select", payload: { organizationId: e.target.value } })
        }
        marginReset
        inputSize="small"
      />
    );
  }

  return !!image ? (
    <img
      className={classNames("NavigationSidebar_logo", {
        NavigationSidebar_logo___collapsed: !isSidebarOpen,
      })}
      src={image}
      alt={"Future Fashion"}
    />
  ) : (
    <div className="NavigationSidebar_sign">
      {isSidebarOpen ? organization?.name : initials(organization?.name)}
    </div>
  );
}

export default OrganizationSelect;
