import { z } from "zod";

import { validation } from "./validation";

const Env = z.intersection(
  z.object({
    VITE_BASE_URL: z.string().min(1),
    VITE_API_BASE_URL: z.string().min(1),
    VITE_AWS_COGNITO_USER_POOL_ID: z.string().min(1),
    VITE_AWS_COGNITO_CLIENT_ID: z.string().min(1),
    VITE_AWS_COGNITO_DOMAIN: z.string().min(1),
    VITE_AWS_REGION: z.string().min(1),
    VITE_3DVIEWER_URL: z.string().min(1),
    VITE_3DAM_API_BASE_URL: z.string().min(1),
    VITE_CONTACT_US_EMAIL: z.string().min(1),
    VITE_FF_ORGANIZATION_ID: z.string().uuid().min(1),
    VITE_SALES_FORM_URL: z.string().min(1),
    VITE_ZAKEKE_VTO_IFRAME_DOMAIN: z.string().min(1),
    VITE_ZAKEKE_API_DOMAIN: z.string().min(1),
  }),
  z.union([
    z.object({
      MODE: z.literal("development"),
    }),
    z.object({
      MODE: z.union([z.literal("stage"), z.literal("production")]),
      VITE_SENTRY_DSN: z.string().min(1),
      VITE_SENTRY_RELEASE: z.string().min(1),
    }),
  ]),
);

type Env = z.infer<typeof Env>;

export const ENV: Env =
  import.meta.env.STORYBOOK_ENV || import.meta.env.MODE === "test"
    ? {
        MODE: "development",
        VITE_BASE_URL: "http://localhost:3000",
        VITE_API_BASE_URL: "http://localhost:3000",
        VITE_AWS_COGNITO_USER_POOL_ID: "us-east-1_123456789",
        VITE_AWS_COGNITO_CLIENT_ID: "123456789",
        VITE_AWS_COGNITO_DOMAIN: "http://localhost:3000",
        VITE_AWS_REGION: "us-east-1",
        VITE_3DVIEWER_URL:
          "https://babylon-3dviewer.stg.futurefashionsolution.com",
        VITE_3DAM_API_BASE_URL:
          "https://uj3uc2563d.execute-api.eu-central-1.amazonaws.com/stage/",
        VITE_CONTACT_US_EMAIL: "test@test.it",
        VITE_FF_ORGANIZATION_ID: "00000000-0000-0000-0000-000000000000",
        VITE_SALES_FORM_URL: "https://3dquote-stg.vercel.app/user",
        VITE_ZAKEKE_VTO_IFRAME_DOMAIN: "https://test-admin.zakeke.com",
        VITE_ZAKEKE_API_DOMAIN: "https://test-api.zakeke.com",
      }
    : validation(Env, import.meta.env);
