import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";
import { StatusCode } from "../status";
import { StatusGroupCode } from "../status-groups/models";

export const SkuType = z.union([
  z.literal("COMMERCIAL"),
  z.literal("PUBLIC_DEMO"),
  z.literal("PRIVATE_DEMO"),
]);

export type SkuType = z.infer<typeof SkuType>;

export const SkuProjectType = z.union([
  z.literal("VIEWER"),
  z.literal("CUSTOMIZER"),
  z.literal("CONFIGURATOR"),
]);

export type SkuProjectType = z.infer<typeof SkuProjectType>;

export const SkuImageDto = z.object({
  id: z.string().uuid(),
  url: z.string(),
  fileName: z.string().optional(),
  fileSize: z.number().optional(),
  uploaded: z.boolean().optional(), // TODO remove optional
  main: z.boolean().optional(), // TODO remove optional
  uploadDate: z.string().optional(),
});

export const SkuImagesDto = PaginationResponseBuilder(SkuImageDto);

export const SkuDto = z.object({
  id: z.string().uuid(),
  productId: z.string(),
  productName: z.optional(z.string()),

  apiKey: z.string(),

  deadline: z.optional(z.string()),
  startDate: z.optional(z.string()),
  endDate: z.optional(z.string()),

  imageUrl: z.optional(z.string().url()),

  companyId: z.optional(z.string().uuid()),
  companyName: z.optional(z.string()),

  brandId: z.optional(z.string().uuid()),
  brandName: z.optional(z.string()),

  artist3DId: z.optional(z.string().uuid()),
  artist3DName: z.optional(z.string()),
  artist3DSurname: z.optional(z.string()),

  projectManagerId: z.optional(z.string().uuid()),
  projectManagerName: z.optional(z.string()),
  projectManagerSurname: z.optional(z.string()),

  orderNumber: z.optional(z.string()),

  statusId: z.optional(z.string().uuid()),
  statusCode: z.optional(z.string()),
  statusName: z.optional(z.string()),
  statusGroupCode: z.optional(z.string()),
  statusGroupName: z.optional(z.string()),
  width: z.optional(z.number()),
  height: z.optional(z.number()),
  length: z.optional(z.number()),
  dimension: z.optional(z.string()),
  description: z.optional(z.string()),

  attachmentUrl: z.optional(z.string().url()),
  attachmentSize: z.optional(z.number()),

  gifUrl: z.optional(z.string().url()),
  videoUrl: z.optional(z.string().url()),

  tags: z.array(z.object({ name: z.string(), id: z.string().uuid() })),

  type: SkuType,
  projectType: SkuProjectType,
});

export const SkuCreateCommand = z.object({
  productId: z
    .string()
    .min(1)
    .regex(/^[a-zA-Z0-9-_.]+$/),
  productName: z.string().min(3).max(120),
  type: SkuType,
  companyId: z.string().uuid(),
  projectType: SkuProjectType,
  statusCode: z.union([
    z.literal("DRAFT"),
    z.literal("APPROVED"),
    z.literal("REQUEST_3D"),
  ]),
  width: z.optional(z.number()),
  height: z.optional(z.number()),
  length: z.optional(z.number()),
  description: z.optional(z.string()),
  orderId: z.optional(z.string().uuid()),
});

export type SkuCreateCommand = z.infer<typeof SkuCreateCommand>;

export type SkuCreateError = {
  productId?: "EXISTING_PRODUCT_ID" | "REGEX" | "GENERIC";
  productName?: "PRODUCT_NAME" | "GENERIC";
};

export const SkuStatusCommand = z.union([
  z.literal("NEXT"),
  z.literal("REJECT"),
  z.literal("READY"),
  z.literal("APPROVE"),
]);

export type SkuStatusCommand = z.infer<typeof SkuStatusCommand>;

export const SkuPostBody = z.object({
  fileName: z.string(),
});

export const UpdateSkuDto = z.object({
  deadline: z.string().optional(),
  status: SkuStatusCommand.optional(),
  type: SkuType.optional(),
  hasAttachment: z.boolean().optional(),
  disabled: z.boolean().optional(),
  description: z.string().optional(),
});

export const SkuBulkUpdate = z.object({
  ids: z.array(z.string().uuid()),
  artist3DId: z.string().uuid().optional().nullable(),
  projectManagerId: z.string().uuid().optional().nullable(),
  disabled: z.boolean().optional(),
});

export const SkuBulkUpdateResponse = z.object({
  updated: z.number(),
});

export const SkuBulkDelete = z.object({
  ids: z.array(z.string().uuid()).optional(),
  comapnyId: z.string().uuid().optional(),
  type: SkuType.optional(),
  organizationId: z.string().uuid().optional(),
});

export const SkuBulkDeleteResponse = z.object({
  deleted: z.number(),
});

export const SkusDto = PaginationResponseBuilder(SkuDto);

export const SkuParamsDto = z.object({
  id: z.string().uuid(),
});

export type SkuImageDto = z.infer<typeof SkuImageDto>;
export type SkuImagesDto = z.infer<typeof SkuImagesDto>;
export type SkuDto = z.infer<typeof SkuDto>;
export type SkusDto = z.infer<typeof SkusDto>;
export type SkuParamsDto = z.infer<typeof SkuParamsDto>;
export type SkuPostBody = z.infer<typeof SkuPostBody>;
export type UpdateSkuDto = z.infer<typeof UpdateSkuDto>;
export type SkuBulkUpdate = z.infer<typeof SkuBulkUpdate>;
export type SkuBulkUpdateResponse = z.infer<typeof SkuBulkUpdateResponse>;
export type SkuBulkDelete = z.infer<typeof SkuBulkDelete>;
export type SkuBulkDeleteResponse = z.infer<typeof SkuBulkDeleteResponse>;

export type SkuOrderByPrinciple =
  | "productId"
  | "productName"
  | "deadline"
  | "brandName"
  | "artist3DName"
  | "artist3DSurname"
  | "projectManagerName"
  | "projectManagerSurname"
  | "statusCode"
  | "createdAt"
  | "projectType"
  | "companyName";

export const SkuSortBy = z.union([
  z.literal("productId.asc"),
  z.literal("productId.desc"),
  z.literal("productName.asc"),
  z.literal("productName.desc"),
  z.literal("brandName.asc"),
  z.literal("brandName.desc"),
  z.literal("artist3DName.asc"),
  z.literal("artist3DName.desc"),
  z.literal("artist3DSurname.asc"),
  z.literal("artist3DSurname.desc"),
  z.literal("projectManagerName.asc"),
  z.literal("projectManagerName.desc"),
  z.literal("projectManagerSurname.asc"),
  z.literal("projectManagerSurname.desc"),
  z.literal("deadline.asc"),
  z.literal("deadline.desc"),
  z.literal("statusCode.asc"),
  z.literal("statusCode.desc"),
  z.literal("createdAt.asc"),
  z.literal("createdAt.desc"),
  z.literal("projectType.asc"),
  z.literal("projectType.desc"),
  z.literal("companyName.asc"),
  z.literal("companyName.desc"),
]);

export type SkuSortBy = z.infer<typeof SkuSortBy>;

export const SkusFiltersAndOrderParams = z.object({
  categoryIds: z.array(z.string()).optional(),
  companyId: z.string().optional(),
  organizationId: z.string().optional(),
  projectType: z.array(SkuProjectType).optional(),
  partnerId: z.string().optional(),
  statusId: z.string().optional(),
  statusCodes: z.array(StatusCode).optional(),
  statusGroupCodes: z.array(StatusGroupCode).optional(),
  statusGroupCodeId: z.string().optional(),
  artist3DId: z.string().optional(),
  projectManagerId: z.string().optional(),
  sortBy: z.optional(z.array(SkuSortBy)),
  types: z.array(SkuType).optional(),
  productId: z.string().optional(),
  productName: z.string().optional(),
  brandIds: z.array(z.string().uuid()).optional(),
  tagIds: z.array(z.string().uuid()).optional(),
  hasGif: z.boolean().optional(),
  hasVideo: z.boolean().optional(),
  has3dModel: z.boolean().optional(),
  disabled: z.boolean().optional(),
  allDemos: z.boolean().optional(),
  orderId: z.string().uuid().optional(),
  estimateId: z.string().optional(),
});

export type SkusFiltersAndOrderParams = z.infer<
  typeof SkusFiltersAndOrderParams
>;

export const SkuNoteActor = z.union([
  z.literal("CUSTOMER"),
  z.literal("ARTIST_3D"),
  z.literal("PROJECT_MANAGER"),
]);

export type SkuNoteActor = z.infer<typeof SkuNoteActor>;

//create sku note
export const CreteSkuNoteDto = z.object({
  text: z.string(),
  sender: SkuNoteActor,
  receiver: SkuNoteActor,
  draft: z.boolean().optional(),
});

export type CreteSkuNoteDto = z.infer<typeof CreteSkuNoteDto>;

//update sku note
export const UpdateSkuNoteDto = z.object({
  text: z.string().optional(),
});

export type UpdateSkuNoteDto = z.infer<typeof UpdateSkuNoteDto>;

//sku note detail
export const SkuNoteDto = z.object({
  id: z.string().uuid(),
  text: z.string(),
  sender: SkuNoteActor,
  receiver: SkuNoteActor,
  dateTime: z.string(),
  draft: z.boolean(),
  received: z.boolean(),
});

export type SkuNoteDto = z.infer<typeof SkuNoteDto>;

//sku note list
export const SkuNotesParams = z.object({
  senders: z.array(SkuNoteActor).optional(),
  receivers: z.array(SkuNoteActor).optional(),
  received: z.boolean().optional(),
  draft: z.boolean().optional(),
  drafter: SkuNoteActor.optional(),
  sortBy: z
    .array(
      z.union([
        z.literal("dateTime.asc"),
        z.literal("dateTime.desc"),
        z.literal("draft.asc"),
        z.literal("draft.desc"),
      ]),
    )
    .optional(),
});

export type SkuNotesParams = z.infer<typeof SkuNotesParams>;

export const SkuNotesDto = PaginationResponseBuilder(SkuNoteDto);
export type SkuNotesDto = z.infer<typeof SkuNotesDto>;

//sku note params for list
export const SkuNotesParamsDto = z.object({
  skuId: z.string().uuid(),
});

export type SkuNotesParamsDto = z.infer<typeof SkuNotesParamsDto>;

//sku note params for detail
export const SkuNoteDetailParamsDto = z.object({
  id: z.string().uuid(),
  skuId: z.string().uuid(),
});

export type SkuNoteDetailParamsDto = z.infer<typeof SkuNoteDetailParamsDto>;

//bulk validate
export const BulkValidateReqDto = z.array(
  z.object({
    referenceId: z.string().uuid(),
    productId: z.string(),
    productName: z.string(),
    brandId: z.string().uuid(),
    categoryId: z.string().uuid(),
    orderId: z.string().uuid(),
  }),
);

export const Sku3dModelType = z.union([
  z.literal("MESH"),
  z.literal("MATERIAL"),
]);
export type Sku3dModelType = z.infer<typeof Sku3dModelType>;
//sku-3d-model detail
export const Sku3dModelDto = z.object({
  id: z.string().uuid(),
  file3dName: z.string(),
  fileEnvironmentName: z.nullable(z.optional(z.string())),
  type: Sku3dModelType,
  description: z.optional(z.string()),
  file3dUrl: z.optional(z.string().url()),
  file3dSize: z.optional(z.number()),
  file3dUploadDate: z.string().optional(),
  hasFile3d: z.boolean(),
  filePreviewUrl: z.optional(z.string().url()),
  filePreviewSize: z.optional(z.number()),
  fileEnvironmentUrl: z.optional(z.string().url()),
  fileEnvironmentSize: z.optional(z.number()),
  hasFilePreview: z.boolean(),
  viewerUrl: z.optional(z.string()),
  hasFileEnvironment: z.boolean().optional(),
});

export type Sku3dModelDto = z.infer<typeof Sku3dModelDto>;

//sku-3d-model list
export const Sku3dModelsDto = PaginationResponseBuilder(Sku3dModelDto);
export type Sku3dModelsDto = z.infer<typeof Sku3dModelsDto>;

//create sku-3d-model
export const CreateSku3dModelDto = z.object({
  file3dName: z.string().endsWith(".glb"),
  type: Sku3dModelType,
  description: z.string().min(1).optional(),
  fileEnvironmentName:
    z.optional(z.string().endsWith(".hdr") || z.string().endsWith(".dds")) ||
    z.string().endsWith(".env"),
});

export type CreateSku3dModelDto = z.infer<typeof CreateSku3dModelDto>;

//update sku-3d-model
export const UpdateSku3dModelDto = z.object({
  type: z.optional(Sku3dModelType),
  description: z.optional(z.string().min(1)),
  hasFile3d: z.optional(z.boolean()),
  hasFileEnvironment: z.optional(z.boolean()),
  hasFilePreview: z.optional(z.boolean()),
  fileEnvironmentName:
    z.optional(z.string().endsWith(".hdr") || z.string().endsWith(".dds")) ||
    z.string().endsWith(".env"),
});

export type UpdateSku3dModelDto = z.infer<typeof UpdateSku3dModelDto>;

//sku-3d-model params for detail
export const Sku3dModelDetailParamsDto = z.object({
  skuId: z.string().uuid(),
  id: z.string().uuid(),
});

export type Sku3dModelDetailParamsDto = z.infer<
  typeof Sku3dModelDetailParamsDto
>;

//sku-3d-model querystring
export const Sku3dModelParams = z.object({
  hasFile3d: z.optional(z.boolean()),
  sortBy: z.optional(
    z.array(
      z.union([
        z.literal("file3dUploadDate.asc"),
        z.literal("file3dUploadDate.desc"),
      ]),
    ),
  ),
  type: z.optional(Sku3dModelType),
});

export type Sku3dModelParams = z.infer<typeof Sku3dModelParams>;

export const BulkValidateErrorsDto = z.union([
  z.literal("BRAND_NOT_FOUND"),
  z.literal("CATEGORY_NOT_FOUND"),
  z.literal("ORDER_NOT_FOUND"),
  z.literal("EXISTING_PRODUCT_ID"),
  z.literal("REPEATED_PRODUCT_ID"),
]);
export const BulkValidateResDto = z.array(
  z.object({
    referenceId: z.string().uuid(),
    errorCode: z.optional(BulkValidateErrorsDto),
  }),
);

export type BulkValidateErrorsDto = z.infer<typeof BulkValidateErrorsDto>;
export type BulkValidateReqDto = z.infer<typeof BulkValidateReqDto>;
export type BulkValidateResDto = z.infer<typeof BulkValidateResDto>;

//bulk create
export const SkuRequestDto = z.object({
  productId: z
    .string()
    .min(1)
    .regex(/^[a-zA-Z0-9-_.]+$/),
  productName: z.string().min(3).max(120),
  width: z.number().optional(),
  height: z.number().optional(),
  length: z.number().optional(),
  description: z.string().max(1024).optional().nullable(),
  type: SkuType,
  projectType: SkuProjectType,
  brandId: z.string().uuid(),
  categoryId: z.string().uuid(),
  orderId: z.string().uuid(),
});

export type SkuRequestDto = z.infer<typeof SkuRequestDto>;

//create zip
export const CreateZipReqDto = z.object({
  skuIds: z.array(z.string().uuid()),
});

export const CreateZipResDto = z.object({
  url: z.string().url(),
});

export type CreateZipReqDto = z.infer<typeof CreateZipReqDto>;
export type CreateZipResDto = z.infer<typeof CreateZipResDto>;
