import { PaginationQuery } from "services/common/models/pagination";
import { saasApiClient } from "services/http/saas-api-client";

import {
  CreateTagDto,
  TagDto,
  TagFiltersDto,
  TagsDto,
  UpdateTagDto,
} from "./model";

const entityPath = "/tags";

const list = (
  filters?: TagFiltersDto,
  pagination?: PaginationQuery,
): Promise<TagsDto> =>
  saasApiClient.getPaginated(`${entityPath}/`, TagsDto, pagination, filters);

const create = (tag: CreateTagDto): Promise<TagDto> =>
  saasApiClient.post(`${entityPath}/`, tag, TagDto);

const update = (id: string, tag: UpdateTagDto): Promise<TagDto> =>
  saasApiClient.patch(`${entityPath}/${id}`, tag, TagDto);

const remove = (id: string): Promise<TagDto> =>
  saasApiClient.delete(`${entityPath}/${id}`);

export const tagHttpClient = {
  list,
  create,
  update,
  remove,
};
