import { PaginationQuery } from "services/common/models/pagination";
import { saasApiClient } from "services/http/saas-api-client";

import { RequestConfig } from "@/libs";

import {
  CreateOrganizationDto,
  OrganizationDto,
  OrganizationFilters,
  OrganizationsDto,
  UpdateOrganizationDto,
} from "./models";

const entityPath = "/organizations";

const list = (
  filters?: OrganizationFilters,
  pagination?: PaginationQuery,
  config?: RequestConfig,
): Promise<OrganizationsDto> =>
  saasApiClient.getPaginated(
    `${entityPath}`,
    OrganizationsDto,
    pagination,
    filters,
    config,
  );

const get = (id: string): Promise<OrganizationDto> =>
  saasApiClient.get(`${entityPath}/${id}`, OrganizationDto);

const create = (
  organization: CreateOrganizationDto,
): Promise<OrganizationDto> =>
  saasApiClient.post(`${entityPath}`, organization, OrganizationDto);

const update = (
  id: string,
  organization: UpdateOrganizationDto,
): Promise<OrganizationDto> =>
  saasApiClient.patch(`${entityPath}/${id}`, organization, OrganizationDto);

export const organizationHttpClient = {
  list,
  get,
  create,
  update,
};
