import { ZodType, z } from "zod";

import * as R from "./Result";
import { ValidationError } from "./errors";

export function validation<Schema extends ZodType>(
  schema: Schema,
  data: unknown,
): z.infer<Schema> {
  const result = schema.safeParse(data);

  if (result.success) return result.data;

  throw new ValidationError(
    result.error.issues
      .map(
        ({ code, message, path }) => `
error: ${code}
path: ${path}
message: ${message}
`,
      )
      .join("\n"),
  );
}

export function validationSafe<Schema extends ZodType>(
  schema: Schema,
  data: unknown,
): R.Result<ValidationError, z.infer<Schema>> {
  const result = schema.safeParse(data);

  if (result.success) return R.success(result.data);

  return R.failure(
    new ValidationError(
      result.error.issues
        .map(
          ({ code, message, path }) => `
error: ${code}
path: ${path}
message: ${message}
`,
        )
        .join("\n"),
    ),
  );
}
