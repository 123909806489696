import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const StatusGroupCode = z.union([
  z.literal("DRAFT"),
  z.literal("REQUEST_3D"),
  z.literal("CHECK_REFERENCE"),
  z.literal("WORK_IN_PROGRESS"),
  z.literal("REVIEW_3D"),
  z.literal("APPROVED"),
]);

export type StatusGroupCode = z.infer<typeof StatusGroupCode>;

export const StatusGroupDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: StatusGroupCode,
});

export const StatusGroupsListDto = PaginationResponseBuilder(StatusGroupDto);

export type StatusGroupDto = z.infer<typeof StatusGroupDto>;
export type StatusGroupsListDto = z.infer<typeof StatusGroupsListDto>;
