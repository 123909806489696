import classNames from "classnames";
import InputWrapper, {
  InputWrapperProps,
} from "components/Input/components/InputWrapper.tsx";

import { InputSize } from "@/@types";

type TextAreaProps = InputWrapperProps & {
  size?: InputSize;
  error?: boolean;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

const TextArea: React.FC<TextAreaProps> = ({
  // WRAPPER
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,
  htmlFor,

  // INPUT
  size = "standard",
  error = false,
  ...props
}) => {
  const wrapperProps = {
    label,
    description,
    marginReset,
    labelClassName,
    required,
    htmlFor: htmlFor ?? props.id,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <textarea
        {...props}
        className={classNames({
          "is-small": size === "small",
          "is-wide": size === "wide",
          "has-error": error,
        })}
      />
    </InputWrapper>
  );
};

export default TextArea;
