import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type SidebarState = {
  isSidebarOpen: boolean;
  toggleSidebar: (forceState?: boolean) => void;
};

export const useSidebar = create<SidebarState>()(
  devtools(
    persist(
      (set) => ({
        isSidebarOpen: true,
        toggleSidebar: (forceState?: boolean) => {
          set((state) => ({
            ...state,
            isSidebarOpen: forceState ?? !state.isSidebarOpen,
          }));
        },
      }),
      {
        name: "SidebarState",
      },
    ),
  ),
);
