import { useSelector } from "@xstate/react";
import { Navigate } from "react-router-dom";

import { LoggedUser } from "@/@types";
import useAuth from "@/hooks/auth/useAuth.ts";
import useCheckUserGroup from "@/hooks/auth/useCheckUserGroup.ts";
import { UserGroupCode } from "@/services";

interface AuthGuardedRouteProps {
  children: React.ReactNode | ((props: { user: LoggedUser }) => JSX.Element);
  userGroupRestrictions?: UserGroupCode | UserGroupCode[];
  requiresCompletedProfile?: boolean;
}

const ProtectedRoute = ({
  children,
  requiresCompletedProfile = true,
  userGroupRestrictions,
}: AuthGuardedRouteProps) => {
  const authActor = useAuth();
  const hasUserGroup = useCheckUserGroup();
  const state = useSelector(authActor, (snapshot) => snapshot);
  const user = useSelector(authActor, (snapshot) => snapshot.context.user);

  if (!user || state.matches("unauthorized")) {
    return (
      <Navigate to={`/sign-in?redirect=${window.location.pathname}`} replace />
    );
  }

  const hasFilledDetails = !!user.jobTitleId; // if jobTitleId has a value, we deduce the profile is complete

  if (
    user &&
    !hasFilledDetails &&
    requiresCompletedProfile &&
    hasUserGroup(["prospect"])
  )
    return <Navigate to="/complete-profile" replace />;

  if (userGroupRestrictions && !hasUserGroup(userGroupRestrictions)) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      {typeof children === "function" ? children({ user }) : children || null}
    </>
  );
};

export default ProtectedRoute;
