import { useSelector } from "@xstate/react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { LoggedUser } from "@/@types";
import { isAuthLoading } from "@/blackbox/machines/auth/auth.ts";
import { getIntegrationIdToken } from "@/blackbox/storage/auth.ts";
import useAuth from "@/hooks/auth/useAuth.ts";
import useUser from "@/hooks/auth/useUser.ts";
import { UserGroupCode, rpcHttpClient } from "@/services";

/**
 * @deprecated use useUser, useAuth and other newly created hooks
 */
export const useAuthentication = () => {
  const location = useLocation();
  const authActor = useAuth();
  const authState = useSelector(authActor, (snapshot) => snapshot);

  /**
   * @deprecated prefer useUser directly os useAuth
   */
  const user = useUser();

  /**
   * @deprecated calculate on spot if needed
   */
  const afterLoginRedirectRoute = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("redirect") ?? "/gallery";
  }, [location.search]);

  /**
   * @deprecated alternative not available atm
   * @param addonName
   */
  const activateZakekeAddon = async (addonName: string) => {
    // TODO refactor this, we should save this on the state, not on the local storage
    const integrationToken = getIntegrationIdToken();

    if (!integrationToken)
      throw new Error("unable to retrieve token from local storage");

    const result = await rpcHttpClient.user.activateZakekeAddon({
      zakekeApiToken: integrationToken,
      zakekeAddOnName: addonName,
    });

    authActor.send({ type: "assignIntegration", payload: result });
  };

  return {
    user,
    /**
     * @deprecated prefer isAuthLoading from machine
     */
    isLoading: isAuthLoading(authState),
    afterLoginRedirectRoute,
    /**
     * @deprecated prefer authState context.integration directly
     */
    zakekeAuthData: authState.context.integration,
    /**
     * @deprecated prefer useAuth directly
     */
    getUser: () => authActor.send({ type: "verify" }),
    activateZakekeAddon,
  };
};

/**
 * @deprecated use useCheckUserGroup
 * @param group
 * @param user
 */
export function hasUserGroup(
  group: UserGroupCode | UserGroupCode[],
  user: LoggedUser | null | undefined,
) {
  return user?.groupCode ? group.includes(user.groupCode) : false;
}

/**
 * @deprecated use useCheckUserGroup directly
 * @param user
 */
export const useCanSelectOrganization = (user: LoggedUser | null | undefined) =>
  useMemo(
    () => hasUserGroup(["ffsuperadmin", "ffadmin", "pm", "artist3d"], user),
    [user],
  );

/**
 * @deprecated use useCheckUserGroup directly
 * @param user
 */
export const useCanSelectCompany = (user: LoggedUser | null | undefined) =>
  useMemo(
    () =>
      hasUserGroup(
        ["ffsuperadmin", "ffadmin", "orgadmin", "pm", "artist3d"],
        user,
      ),
    [user],
  );
