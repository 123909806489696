import { saasApiClient } from "services/http/saas-api-client.ts";
import { z } from "zod";

const entityPath = "/auth";

const ChallengeResponse = z.object({
  type: z.string().optional(),
  challengeMetadata: z.record(z.string(), z.any()).optional(),
});

const AuthResponse = z.object({
  token: z.string().optional(),
  refreshToken: z.string().optional(),
});

const LoginResponse = AuthResponse.merge(ChallengeResponse);

export type AuthResponse = z.infer<typeof AuthResponse>;
export type ChallengeResponse = z.infer<typeof ChallengeResponse>;
export type LoginResponse = z.infer<typeof LoginResponse>;

export const authHttpClient = {
  refresh(token: string): Promise<AuthResponse> {
    return saasApiClient.post(`${entityPath}/refresh`, { token }, AuthResponse);
  },
  login(username: string, password: string): Promise<LoginResponse> {
    return saasApiClient.post(
      `${entityPath}/login`,
      { username, password },
      LoginResponse,
    );
  },
  logout(token: string): Promise<void> {
    return saasApiClient.post(`${entityPath}/logout`, { token });
  },
  resetPassword(username: string): Promise<void> {
    return saasApiClient.post(`${entityPath}/reset-password`, { username });
  },
  confirmResetPassword(username: string, newPassword: string, token: string) {
    return saasApiClient.post(`${entityPath}/confirm-reset-password`, {
      username,
      newPassword,
      token,
    });
  },
  respondToChallenge(
    username: string,
    newPassword: string,
    session: string,
  ): Promise<AuthResponse> {
    return saasApiClient.post(
      `${entityPath}/respond-to-challenge`,
      {
        challenge: {
          type: "NEW_PASSWORD_REQUIRED",
          challengeMetadata: {
            username,
            newPassword,
            session,
          },
        },
      },
      AuthResponse,
    );
  },
  changePassword(previousPassword: string, newPassword: string): Promise<void> {
    return saasApiClient.post(`${entityPath}/change-password`, {
      previousPassword,
      newPassword,
    });
  },
  register(username: string): Promise<void> {
    return saasApiClient.post(`${entityPath}/register`, {
      username,
    });
  },
};
