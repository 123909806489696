import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const StatusCode = z.union([
  z.literal("DRAFT"),
  z.literal("REQUEST_3D"),
  z.literal("CHECK_REFERENCE"),
  z.literal("READY_TO_START"),
  z.literal("WORK_IN_PROGRESS"),
  z.literal("REVIEW_IN_PROGRESS"),
  z.literal("CHANGE_3D"),
  z.literal("CUSTOMER_REVIEW"),
  z.literal("REQUIRED_CHANGE"),
  z.literal("APPROVED"),
  z.literal("DISABLED"),
]);

export type StatusCode = z.infer<typeof StatusCode>;

export const StatusDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: StatusCode,
  groupCode: z.string(),
  groupId: z.string().uuid(),
});

export const StatusListDto = PaginationResponseBuilder(StatusDto);

export type StatusDto = z.infer<typeof StatusDto>;
export type StatusListDto = z.infer<typeof StatusListDto>;
