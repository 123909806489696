import { saasApiClient } from "services/http/saas-api-client";

import {
  BulkValidateReqDto,
  BulkValidateResDto,
  CreateZipReqDto,
  CreateZipResDto,
} from "../sku/models";
import {
  AssignTagToSkusReqDto,
  AssignTagToSkusResDto,
  AvailableTagsReqDto,
  AvailableTagsResDto,
  RemoveTagFromSkusReqDto,
  RemoveTagFromSkusResDto,
} from "../tag";
import {
  ActivateZakekeAddonReqDto,
  ActivateZakekeAddonResDto,
  LoginZakekeReqDto,
  LoginZakekeResDto,
  PermissionsZakekeResDto,
} from "../user";
import {
  AnalyticsTokenDto,
  ConfirmNotesReqDto,
  ConfirmNotesResDto,
  CreateGifZipReqDto,
  CreateGifZipResDto,
  CreateVideoZipReqDto,
  CreateVideoZipResDto,
  GenerateGifVideoReqDto,
  GenerateGifVideoResDto,
  ReceiveNotesReqDto,
  ReceiveNotesResDto,
  SearchItemsReqDto,
  SearchItemsResDto,
  SkuBulkReadRequestDto,
  SkuBulkReadResponseDto,
  SkuCSVBulkUploadDTO,
  SkuCSVBulkUploadResponseDTO,
  SkuStatusOptionsReqDto,
  SkuStatusOptionsResDto,
  UpdateViewerConfigReqDto,
  UpdateViewerConfigResDto,
} from "./models";

const entityPath = "/rpc";

const user = {
  syncExternals: (): Promise<void> =>
    saasApiClient.post(`${entityPath}/users/sync-externals`),
  loginZakeke: (req: LoginZakekeReqDto): Promise<LoginZakekeResDto> =>
    saasApiClient.post(
      `${entityPath}/users/login-zakeke`,
      req,
      LoginZakekeResDto,
    ),
  permissionsZakeke: (companyId: string): Promise<PermissionsZakekeResDto> =>
    saasApiClient.get(
      `${entityPath}/users/permissions-zakeke/${companyId}`,
      PermissionsZakekeResDto,
    ),
  activateZakekeAddon: (
    req: ActivateZakekeAddonReqDto,
  ): Promise<ActivateZakekeAddonResDto> =>
    saasApiClient.post(
      `${entityPath}/users/activate-zakeke-addon`,
      req,
      ActivateZakekeAddonResDto,
    ),
};

const analytics = {
  login: (): Promise<AnalyticsTokenDto> =>
    saasApiClient.post(
      `${entityPath}/analytics/login`,
      null,
      AnalyticsTokenDto,
    ),
};

const skus = {
  bulkInsert: (
    params: SkuCSVBulkUploadDTO,
  ): Promise<SkuCSVBulkUploadResponseDTO> =>
    saasApiClient.post(
      `${entityPath}/skus/bulk-insert`,
      params,
      SkuCSVBulkUploadResponseDTO,
    ),
  bulkRead: (params: SkuBulkReadRequestDto): Promise<SkuBulkReadResponseDto> =>
    saasApiClient.post(
      `${entityPath}/skus/bulk-read`,
      params,
      SkuBulkReadResponseDto,
    ),
  statusOptions: (params: SkuStatusOptionsReqDto) =>
    saasApiClient.post(
      `${entityPath}/skus/status-options`,
      params,
      SkuStatusOptionsResDto,
    ),
  searchItems: (params: SearchItemsReqDto) =>
    saasApiClient.post(
      `${entityPath}/skus/search-items`,
      params,
      SearchItemsResDto,
    ),
  createGifZip: (req: CreateGifZipReqDto): Promise<CreateGifZipResDto> =>
    saasApiClient.post(
      `${entityPath}/skus/create-gif-zip`,
      req,
      CreateGifZipResDto,
    ),
  createVideoZip: (req: CreateVideoZipReqDto): Promise<CreateVideoZipResDto> =>
    saasApiClient.post(
      `${entityPath}/skus/create-video-zip`,
      req,
      CreateVideoZipResDto,
    ),
  bulkValidate: (params: BulkValidateReqDto): Promise<BulkValidateResDto> =>
    saasApiClient.post(
      `${entityPath}/skus/bulk-validate`,
      params,
      BulkValidateResDto,
    ),
};

const skuImages = {
  createZip: (req: CreateZipReqDto): Promise<CreateZipResDto> =>
    saasApiClient.post(
      `${entityPath}/sku-images/create-zip`,
      req,
      CreateZipResDto,
    ),
};

const skuNotes = {
  confirm: (params: ConfirmNotesReqDto) =>
    saasApiClient.post(
      `${entityPath}/sku-notes/confirm-notes`,
      params,
      ConfirmNotesResDto,
    ),
  receive: (params: ReceiveNotesReqDto) =>
    saasApiClient.post(
      `${entityPath}/sku-notes/receive-notes`,
      params,
      ReceiveNotesResDto,
    ),
};

const tags = {
  assign: (params: AssignTagToSkusReqDto) =>
    saasApiClient.post(
      `${entityPath}/tags/assign-tag-to-skus`,
      params,
      AssignTagToSkusResDto,
    ),
  remove: (params: RemoveTagFromSkusReqDto) =>
    saasApiClient.post(
      `${entityPath}/tags/remove-tag-from-skus`,
      params,
      RemoveTagFromSkusResDto,
    ),
  availableTags: (params: AvailableTagsReqDto) =>
    saasApiClient.post(
      `${entityPath}/tags/available-tags`,
      params,
      AvailableTagsResDto,
    ),
};

const sku3dModels = {
  createZip: (req: CreateZipReqDto): Promise<CreateZipResDto> =>
    saasApiClient.post(
      `${entityPath}/3d-models/create-zip`,
      req,
      CreateZipResDto,
    ),
  generateGifVideo: (
    req: GenerateGifVideoReqDto,
  ): Promise<GenerateGifVideoResDto> =>
    saasApiClient.post(
      `${entityPath}/3d-models/generate-gif-video`,
      req,
      GenerateGifVideoResDto,
    ),
  updateViewerConfig: (
    req: UpdateViewerConfigReqDto,
  ): Promise<UpdateViewerConfigResDto> =>
    saasApiClient.post(
      `${entityPath}/3d-models/update-viewer-config`,
      req,
      UpdateViewerConfigResDto,
    ),
};

export const rpcHttpClient = {
  user,
  analytics,
  skus,
  skuImages,
  skuNotes,
  tags,
  sku3dModels,
};
