import { PaginationResponseBuilder } from "services/common/models/pagination";
import { z } from "zod";

//tag detail
export const TagDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  brandId: z.string().uuid(),
  typeId: z.string().uuid(),
  typeName: z.string(),
});

export type TagDto = z.infer<typeof TagDto>;

//tag list
export const TagsDto = PaginationResponseBuilder(TagDto);

export type TagsDto = z.infer<typeof TagsDto>;

// querystring
export const TagFiltersDto = z.object({
  brandId: z.optional(z.string()),
  typeId: z.optional(z.string()),
  orderById: z.optional(z.boolean()),
});

export type TagFiltersDto = z.infer<typeof TagFiltersDto>;

//create tag
export const CreateTagDto = z.object({
  name: z.string(),
  brandId: z.string().uuid(),
  typeId: z.string().uuid(),
});

export type CreateTagDto = z.infer<typeof CreateTagDto>;

//update tag
export const UpdateTagDto = z.object({
  name: z.string(),
});

export type UpdateTagDto = z.infer<typeof UpdateTagDto>;

//tag params for detail
export const TagDetailDto = z.object({
  id: z.string().uuid(),
});

export type TagDetailDto = z.infer<typeof TagDetailDto>;

//tag query string
export const TagQuerystringDto = z.object({
  brandId: z.optional(z.string()),
  tagTypeId: z.optional(z.string()),
});

export type TagQuerystringDto = z.infer<typeof TagQuerystringDto>;

//assign tag to skus
export const AssignTagToSkusReqDto = z.object({
  tagId: z.string().uuid(),
  skuIds: z.array(z.string().uuid()),
});

export const AssignTagToSkusResDto = z.object({
  assigned: z.number(),
});

export type AssignTagToSkusReqDto = z.infer<typeof AssignTagToSkusReqDto>;
export type AssignTagToSkusResDto = z.infer<typeof AssignTagToSkusResDto>;

export const AvailableTagsReqDto = z.object({
  skuIds: z.array(z.string().uuid()),
});

const AvailableTagDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  skus: z.number(),
});

export const AvailableTagsResDto = z.array(
  z.object({
    typeId: z.string().uuid(),
    typeName: z.string(),
    tags: z.array(AvailableTagDto),
  }),
);

export type AvailableTagsReqDto = z.infer<typeof AvailableTagsReqDto>;
export type AvailableTagDto = z.infer<typeof AvailableTagDto>;
export type AvailableTagsResDto = z.infer<typeof AvailableTagsResDto>;

//remove tag from skus
export const RemoveTagFromSkusReqDto = z.object({
  tagId: z.string().uuid(),
  skuIds: z.array(z.string().uuid()),
});

export const RemoveTagFromSkusResDto = z.object({
  removed: z.number(),
});

export type RemoveTagFromSkusReqDto = z.infer<typeof RemoveTagFromSkusReqDto>;
export type RemoveTagFromSkusResDto = z.infer<typeof RemoveTagFromSkusResDto>;
