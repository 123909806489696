import { useMachine } from "@xstate/react";
import routes from "configuration/routes.ts";
import { ProviderProps, Suspense, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import machine, {
  AuthActor,
  isAuthLoading,
  registerOnChangeEvents,
} from "@/blackbox/machines/auth/auth.ts";
import FullscreenLoader from "@/components/FullscreenLoader";

type Context = AuthActor | null;

type AuthProvider = {
  children: ProviderProps<Context>["children"];
};

export const AuthContext = createContext<Context>(null);

export const Provider = ({ children }: AuthProvider) => {
  const { pathname } = useLocation();
  const [state, , actorRef] = useMachine(machine, {
    id: "auth",
    systemId: "root-auth-machine",
    input: {
      isIntegration: pathname === routes.accessJwt.path,
    },
  });

  useEffect(() => {
    const cleanup = registerOnChangeEvents(actorRef);

    return () => cleanup();
  }, []);

  return (
    <AuthContext.Provider value={actorRef}>
      {isAuthLoading(state) ? (
        <FullscreenLoader />
      ) : (
        <Suspense>{children}</Suspense>
      )}
    </AuthContext.Provider>
  );
};
