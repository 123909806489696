import React, { forwardRef } from "react";

import closeIcon from "@/svg/close.svg?plain";

import "./Modal.scss";

export const Modal = forwardRef<
  HTMLDialogElement,
  {
    children: React.ReactNode;
    title: string;
    closeModal: () => void;
    closable?: boolean;
    maxWidth?: React.CSSProperties["width"];
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
    overflow?: React.CSSProperties["overflow"];
    padding?: React.CSSProperties["padding"];
  }
>(
  (
    {
      children,
      title,
      closeModal,
      closable = true,
      maxWidth,
      width,
      height,
      overflow,
      padding,
    },
    ref,
  ) => {
    return (
      <dialog
        style={{ maxWidth, width, height, overflow, padding }}
        className="Modal"
        ref={ref}
      >
        {closable && (
          <button onClick={closeModal} className="Modal_closeButton">
            <img
              src={closeIcon}
              alt="close"
              className="Modal_closeButtonImage"
            />
          </button>
        )}
        <h3 className="Modal_title">{title}</h3>
        {children}
      </dialog>
    );
  },
);
