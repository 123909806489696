type Success<T> = {
  type: "success";
  value: T;
};

type Failure<E> = {
  type: "failure";
  error: E;
};

export type Result<E, T> = Success<T> | Failure<E>;

export const success = <T>(value: T): Result<never, T> => ({
  type: "success",
  value,
});
export const failure = <E>(error: E): Result<E, never> => ({
  type: "failure",
  error,
});

export const isSuccess = <E, T>(value: Result<E, T>): value is Success<T> =>
  value.type === "success";

export const isFailure = <E, T>(value: Result<E, T>): value is Failure<E> =>
  value.type === "failure";
