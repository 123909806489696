import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const OrganizationDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  vat: z.optional(z.string()),
  fiscalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  province: z.optional(z.string()),
  city: z.optional(z.string()),
  zipCode: z.optional(z.string()),
  street: z.optional(z.string()),
  email: z.string().email(),
  phone: z.optional(z.string()),
  companyCount: z.optional(z.number()),
  userCount: z.optional(z.number()),
});

export type OrganizationDto = z.infer<typeof OrganizationDto>;

export const OrganizationsDto = PaginationResponseBuilder(OrganizationDto);
export type OrganizationsDto = z.infer<typeof OrganizationsDto>;

export const OrganizationFilters = z.object({
  generic: z.optional(z.string()),
  partnerId: z.optional(z.string()),
  sortBy: z.optional(z.string()),
});

export type OrganizationFilters = z.infer<typeof OrganizationFilters>;

//create organization
export const CreateOrganizationDto = z.object({
  name: z.string(),
  vat: z.optional(z.string()),
  fiscalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  province: z.optional(z.string()),
  city: z.optional(z.string()),
  zipCode: z.optional(z.string()),
  street: z.optional(z.string()),
  email: z.string().email(),
  phone: z.optional(z.string()),
});

export type CreateOrganizationDto = z.infer<typeof CreateOrganizationDto>;

//update organization
export const UpdateOrganizationDto = z.object({
  name: z.optional(z.string()),
  vat: z.optional(z.string()),
  fiscalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  province: z.optional(z.string()),
  city: z.optional(z.string()),
  zipCode: z.optional(z.string()),
  street: z.optional(z.string()),
  email: z.optional(z.string().email()),
  phone: z.optional(z.string()),
});

export type UpdateOrganizationDto = z.infer<typeof UpdateOrganizationDto>;
