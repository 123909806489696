import "./SuiteLogo.scss";

export function SuiteLogo() {
  return (
    <img
      className="SuiteLogo"
      src="/images/zakeke_logo_login.png"
      alt="zakeke dam"
    />
  );
}
