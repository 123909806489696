import { VIEW_GROUP, VIEW_GROUP_WITH_PM } from "@/@types";
import { UserGroupCode } from "@/services";

export type RouteDefinition = {
  readonly path: string;
  readonly userGroupRestrictions?: UserGroupCode | UserGroupCode[];
};

const routes = {
  gallery: {
    path: "/gallery",
    userGroupRestrictions: VIEW_GROUP_WITH_PM,
  },
  galleryDetail: {
    path: "/gallery/:id",
    userGroupRestrictions: VIEW_GROUP_WITH_PM,
  },
  /* galleryDisabled: {
    path: "/disabled",
    userGroupRestrictions: VIEW_GROUP,
  }, */
  skuListDetail: {
    path: "/sku-list/:id",
    userGroupRestrictions: [
      "ffsuperadmin",
      "pm",
      "artist3d",
      "ffadmin",
      "orgadmin",
      "customer",
    ],
  },
  skuList: {
    path: "/sku-list",
    userGroupRestrictions: [
      "ffsuperadmin",
      "pm",
      "artist3d",
      "ffadmin",
      "orgadmin",
      "customer",
    ],
  },
  skuList3DModels: {
    path: "/sku-list/:id/sku-3d-models",
    userGroupRestrictions: [
      "ffsuperadmin",
      "pm",
      "artist3d",
      "ffadmin",
      "orgadmin",
      "customer",
    ],
  },
  skuReview: {
    path: "/sku-review/:id",
    userGroupRestrictions: [...VIEW_GROUP, "pm", "artist3d"],
  },
  skuBulkUpload: {
    path: "/sku-bulk-upload",
    userGroupRestrictions: [
      "ffsuperadmin",
      "pm",
      "artist3d",
      "ffadmin",
      "orgadmin",
      "customer",
    ],
  },
  tagsListing: {
    path: "/tags",
    userGroupRestrictions: [
      "ffsuperadmin",
      "pm",
      "artist3d",
      "ffadmin",
      "orgadmin",
      "customer",
    ],
  },
  invite: {
    path: "/invite",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  signUp: {
    path: "/sign-up",
  },
  signIn: {
    path: "/sign-in",
  },
  resetPassword: {
    path: "/reset-password",
  },
  completeProfile: {
    path: "/complete-profile",
  },
  profile: {
    path: "/profile",
  },
  analytics: {
    path: "/analytics",
    userGroupRestrictions: [
      "customer",
      "prospect",
      "ffadmin",
      "ffsuperadmin",
      "orgadmin",
    ],
  },
  requestDemo: {
    path: "/request-demo",
  },
  confirmSignIn: {
    path: "/confirm-sign-in",
  },
  accessJwt: {
    path: "/access/jwt",
  },
  skuRequests: {
    path: "/requests",
    userGroupRestrictions: [
      "ffsuperadmin",
      "ffadmin",
      "orgadmin",
      "pm",
      "customer",
    ],
  },
  estimates: {
    path: "/estimates",
    userGroupRestrictions: [
      "ffsuperadmin",
      "ffadmin",
      "orgadmin",
      "pm",
      "customer",
    ],
  },
  skuRequestsForm: {
    path: "/requests/:id",
    userGroupRestrictions: [
      "ffsuperadmin",
      "ffadmin",
      "orgadmin",
      "pm",
      "customer",
    ],
  },
  adminOrganizations: {
    path: "/admin/organizations",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  adminOrganizationDetail: {
    path: "/admin/organizations/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  adminOrganizationUsers: {
    path: "/admin/organizations/users",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  adminOrganizationUserDetail: {
    path: "/admin/organizations/users/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  adminCompanies: {
    path: "/admin/companies",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminCompanyDetail: {
    path: "/admin/companies/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin", "customer"],
  },
  adminCompanyUsers: {
    path: "/admin/companies/users",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminCompanyUserDetail: {
    path: "/admin/companies/users/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartners: {
    path: "/admin/partners",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartnerDetail: {
    path: "/admin/partners/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartnerProjectManagers: {
    path: "/admin/partners/project-managers",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartnerProjectManagersDetail: {
    path: "/admin/partners/project-managers/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartner3DArtists: {
    path: "/admin/partners/artists-3d",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminPartner3DArtistsDetail: {
    path: "/admin/partners/artists-3d/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin", "orgadmin"],
  },
  adminUsers: {
    path: "/admin/users",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
  adminUserDetail: {
    path: "/admin/users/:id",
    userGroupRestrictions: ["ffadmin", "ffsuperadmin"],
  },
};

export default routes as {
  [key in keyof typeof routes]: RouteDefinition;
};
