import { saasApiClient } from "../saas-api-client";
import { CategoriesDto, CategoryParamsDto } from "./models";

const entityPath = "/categories";

const list = (filters?: CategoryParamsDto): Promise<CategoriesDto> =>
  saasApiClient.getPaginated(
    `${entityPath}`,
    CategoriesDto,
    undefined,
    filters,
  );

export const categoriesHttpClient = {
  list,
};
