import useAuth from "hooks/auth/useAuth.ts";
import { Link } from "react-router-dom";

import { SuiteLogo } from "@/components";
import { useSidebar } from "@/hooks/useSidebar.hook.ts";
import { ReactComponent as LogoutIcon } from "@/svg/logout.svg?react";
import { ReactComponent as MenuIcon } from "@/svg/menu.svg?react";
import { ReactComponent as UserIcon } from "@/svg/user.svg?react";

import "./Navbar.scss";

type NavbarProps = {
  showMenuButton?: boolean;
};

const Navbar = ({ showMenuButton = true }: NavbarProps) => {
  const authActorRef = useAuth();
  const toggleSidebar = useSidebar((s) => s.toggleSidebar);

  return (
    <header className="main-navbar">
      <div className="left">
        {showMenuButton && (
          <div className="sidebar-toggle">
            <MenuIcon onClick={() => toggleSidebar()} />
          </div>
        )}
        <SuiteLogo />
      </div>
      <div className="right">
        <Link to="/profile" className="action">
          <UserIcon />
        </Link>
        <button
          onClick={() => authActorRef.send({ type: "logout" })}
          className="action"
        >
          <LogoutIcon />
        </button>
      </div>
    </header>
  );
};

export default Navbar;
