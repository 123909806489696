import { Spinner } from "@zakeke/zakeke-ui-widgets";
import classnames from "classnames";

import "./SpinnerWrapper.scss";

interface SpinnerWrapperProps {
  spinning?: boolean;
  children?: React.ReactNode;
}

const SpinnerWrapper: React.FC<SpinnerWrapperProps> = ({
  children,
  spinning = false,
}) => {
  return (
    <div className={classnames("spinner-wrapper", { spinning })}>
      <div className="content">{children}</div>
      <div className="overlay">
        <Spinner />
      </div>
    </div>
  );
};

export default SpinnerWrapper;
