import { z } from "zod";

import { GalleryType } from "@/@types";
import { fileDownloader } from "@/libs";
import { SkuProjectType, SkusFiltersAndOrderParams, UserDto } from "@/services";

export const leftPad = (
  str: string | number,
  padString: string = "0",
  length: number = 2,
) => {
  let s = String(str);
  while (s.length < length) {
    s = padString + s;
  }
  return s;
};

export const validateUuid = (
  uuid: string,
  logger?: { debug: (...args: string[]) => void },
) => {
  logger?.debug("Validating id:", uuid);

  if (z.string().uuid().safeParse(uuid).success) {
    logger?.debug("Valid UUID, loading the ORG from backend", uuid);

    return true;
  } else {
    logger?.debug("Invalid UUID, setting empty form for create mode", uuid);

    return false;
  }
};

export const displayName = (
  name: string | undefined,
  surname: string | undefined,
) => [name, surname].filter(Boolean).join(" ");

export const displayUserName = (user?: Pick<UserDto, "name" | "surname">) =>
  user ? displayName(user.name, user.surname) : "";

export const getProjectTypeColor = (
  projectType: SkuProjectType,
): string | undefined => {
  switch (projectType) {
    case "VIEWER":
      return "#E2F9B9";
    case "CONFIGURATOR":
      return "#FFDEB0";
    case "CUSTOMIZER":
      return "#A5FCFF";
  }
};

export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes === 0) {
    return "0 B";
  }

  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const formattedSize = (sizeInBytes / Math.pow(1024, i)).toFixed(2);

  return formattedSize.replaceAll(/\.?0+$/g, "") + " " + sizes[i];
};

export const initials = (name: string | undefined, size: number = 2) => {
  if (!name) {
    return "";
  }

  const names = name.split(" ");

  if (names.length === 1) {
    return names[0].substring(0, size);
  }

  return names
    .map((n) => n[0])
    .slice(0, size)
    .join("");
};

export const galleryTypeFilters = (
  type: GalleryType,
): Pick<SkusFiltersAndOrderParams, "has3dModel" | "hasGif" | "hasVideo"> => {
  return {
    hasGif: type === "gif" ? true : undefined,
    hasVideo: type === "video" ? true : undefined,
    has3dModel: type === "3d" ? true : undefined,
  };
};

export const handleDownloadCSVTemplate = () => {
  const fileURL = `${window.location.origin}/files/CSV_template.csv`;
  fileDownloader(fileURL, "CSV_Template.csv");
};
