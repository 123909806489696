import { ComponentProps, FC, PropsWithChildren } from "react";

import { LoadingProvider } from "@/hooks";

// reference: https://medium.com/front-end-weekly/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e
export const combineComponents = (
  ...components: FC<PropsWithChildren>[]
): FC<PropsWithChildren> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({
        children,
      }: ComponentProps<FC<PropsWithChildren>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};

const providers = [LoadingProvider];

export const AppContextProvider = combineComponents(...providers);
