import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const BrandFiltersDto = z.object({
  artist3DId: z.string().uuid().optional(),
  projectManagerId: z.string().uuid().optional(),
  companyId: z.string().uuid().optional(),
});

export const BrandDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  companyId: z.string().uuid(),
});

export const UpdateBrandDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  companyId: z.string().uuid(),
});

export const BrandItemDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  companyId: z.string().uuid(),
});

export const BrandsDto = PaginationResponseBuilder(BrandItemDto);

export const CreateBrandDto = z.object({
  name: z.string(),
  companyId: z.string().uuid(),
});

export const BrandUserParamsDto = z.object({
  brandId: z.string().uuid(),
});

export type BrandFiltersDto = z.infer<typeof BrandFiltersDto>;
export type BrandItemDto = z.infer<typeof BrandItemDto>;
export type BrandDto = z.infer<typeof BrandDto>;
export type UpdateBrandDto = z.infer<typeof UpdateBrandDto>;
export type BrandsDto = z.infer<typeof BrandsDto>;
export type CreateBrandDto = z.infer<typeof CreateBrandDto>;
export type BrandUserParamsDto = z.infer<typeof BrandUserParamsDto>;
