import { useActor } from "@xstate/react";
import classNames from "classnames";
import useCheckUserGroup from "hooks/auth/useCheckUserGroup.ts";
import useUser from "hooks/auth/useUser.ts";
import { ReactNode, useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { MenuItems } from "services/http/menu-items.ts";

import machine from "@/blackbox/machines/menu-items";
import routes, { RouteDefinition } from "@/configuration/routes.ts";
import { useI18n } from "@/hooks/useI18n.hook.ts";
import { useSidebar } from "@/hooks/useSidebar.hook.ts";
import { UserGroupCode } from "@/services/http/user/models.ts";
import { ReactComponent as Circle3d } from "@/svg/3d-circle.svg?react";
import { ReactComponent as AddUser } from "@/svg/add-user.svg?react";
import { ReactComponent as AnalyticsFill } from "@/svg/analytics-fill-zakeke.svg?react";
import { ReactComponent as Company } from "@/svg/company-icon.svg?react";
import { ReactComponent as Gallery3D } from "@/svg/gallery3d-icon.svg?react";
import { ReactComponent as Organization } from "@/svg/organization.svg?react";
import { ReactComponent as Partner } from "@/svg/partner.svg?react";
import { ReactComponent as Profile } from "@/svg/profile.svg?react";
import { ReactComponent as Requests } from "@/svg/requests-icon.svg?react";
import { ReactComponent as Tags } from "@/svg/tags-solid.svg?react";

import "./NavigationSidebar.scss";
import CompanySelect from "./components/CompanySelect.tsx";
import Item from "./components/Item.tsx";
import OrganizationSelect from "./components/OrganizationSelect.tsx";

type MenuItem = MenuItems["result"][0] & {
  iconComponent: React.ReactNode;
  route?: RouteDefinition;
};

function NavigationSidebar() {
  const checkUserGroup = useCheckUserGroup();
  const [menuItemsState] = useActor(machine, {
    id: "menuItemsMachine",
  });
  const { t } = useI18n();
  const user = useUser();
  const isSidebarOpen = useSidebar((s) => s.isSidebarOpen);

  const showFor = useCallback(
    (groups: UserGroupCode[] | UserGroupCode) => {
      if (!user?.groupCode) return false;

      return groups.includes(user?.groupCode); // hasUserGroup(groups, user);
    },
    [user],
  );

  const menuItems = useMemo<MenuItem[]>(() => {
    return (
      menuItemsState.output?.map((item) => {
        let iconComponent: ReactNode = <Gallery3D />;

        switch (item.icon) {
          case "icon_3d_rotation": // projects
            iconComponent = <Circle3d />;
            break;
          case "chart_bar_solid": // analytics
            iconComponent = <AnalyticsFill />;
            break;
          case "tags-solid": // tags
            iconComponent = <Tags />;
            break;
          case "mail_bulk_solid":
            iconComponent = <Requests />;
            break;
          case "cube_solid":
          default:
            break;
        }

        return {
          ...item,
          iconComponent,
        };
      }) ?? []
    );
  }, [menuItemsState]);

  return (
    <div
      className={classNames("NavigationSidebar", {
        NavigationSidebar___collapsed: !isSidebarOpen,
      })}
    >
      <div className="NavigationSidebar_content">
        {checkUserGroup([
          "ffsuperadmin",
          "ffadmin",
          "orgadmin",
          "pm",
          "artist3d",
        ]) && (
          <div className="NavigationSidebar_companySelect">
            <CompanySelect />
          </div>
        )}
        <div className="NavigationSidebar_block">
          {menuItems.map((item) => {
            return (
              <Item
                user={user}
                key={item.code}
                label={item.label ?? ""}
                route={item.route ?? ({} as RouteDefinition)}
                icon={item.iconComponent}
              />
            );
          })}
          {showFor(["customer"]) && (
            <div>
              <NavLink
                to={`/admin/companies/${user?.companyId}`}
                className={classNames("NavigationSidebar_item")}
              >
                <span className="NavigationSidebar_itemIcon">
                  {<Company />}
                </span>
                {isSidebarOpen && (
                  <div className="NavigationSidebar_itemLabel">
                    {t("appNavigation.company")}
                  </div>
                )}
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="NavigationSidebar_footer">
        {showFor(["ffadmin", "pm", "ffsuperadmin", "orgadmin"]) && (
          <div className="NavigationSidebar_block">
            <Item
              user={user}
              route={routes.adminOrganizations}
              label={t("appNavigation.organizations")}
              icon={<Organization />}
              subItems={[
                {
                  label: t("appNavigation.organizationUsers"),
                  route: routes.adminOrganizationUsers,
                },
              ]}
            />
            <Item
              user={user}
              route={routes.adminCompanies}
              label={t("appNavigation.companies")}
              icon={<Company />}
              subItems={[
                {
                  label: t("appNavigation.companyUsers"),
                  route: routes.adminCompanyUsers,
                },
              ]}
            />
            <Item
              user={user}
              route={routes.adminPartners}
              label={t("appNavigation.partners")}
              icon={<Partner />}
              subItems={[
                {
                  label: t("appNavigation.partnerPM"),
                  route: routes.adminPartnerProjectManagers,
                },
                {
                  label: t("appNavigation.partner3D"),
                  route: routes.adminPartner3DArtists,
                },
              ]}
            />
            <Item
              user={user}
              route={routes.adminUsers}
              label={t("appNavigation.administrationUsers")}
              icon={<Profile />}
            />
            <Item
              user={user}
              route={routes.invite}
              label={t("appNavigation.invite")}
              icon={<AddUser />}
            />
          </div>
        )}
        <div className="NavigationSidebar_organizationSelect">
          <OrganizationSelect />
        </div>
      </div>
    </div>
  );
}

export default NavigationSidebar;
