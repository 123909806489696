import { PaginationResponseBuilder } from "services/common/models/pagination";
import { z } from "zod";

//tag detail
export const TagTypeDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export type TagTypeDto = z.infer<typeof TagTypeDto>;
export const TagTypesDto = PaginationResponseBuilder(TagTypeDto);
export type TagTypesDto = z.infer<typeof TagTypesDto>;
//tag list

// querystring
export const TagTypeFiltersDto = z.object({
  brandId: z.optional(z.string().uuid()),
});

export type TagTypeFiltersDto = z.infer<typeof TagTypeFiltersDto>;
