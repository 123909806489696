import { z } from "zod";

export const MenuItems = z.object({
  result: z.array(
    z.object({
      code: z.optional(z.string()),
      label: z.optional(z.string()),
      icon: z.optional(z.string()),
      path: z.optional(z.string()),
      iconPath: z.optional(z.string()),
    }),
  ),
});

export type MenuItems = z.infer<typeof MenuItems>;
