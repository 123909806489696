import { z } from "zod";

import { PaginationResponseBuilder } from "../../common/models/pagination";

export const IndustryDto = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
});

export const IndustriesDto = PaginationResponseBuilder(IndustryDto);

export type IndustryDto = z.infer<typeof IndustryDto>;
export type IndustriesDto = z.infer<typeof IndustriesDto>;
