import { ZodTypeAny, z } from "zod";

export const PaginationQuery = z.object({
  offset: z.number().min(0).default(0),
  limit: z.number().min(1).max(100).default(100),
});
export type PaginationQuery = z.infer<typeof PaginationQuery>;

const PaginationResponse = z.object({
  itemCount: z.number().min(0),
  pageIndex: z.number().min(0),
  pageSize: z.number().min(0),
  pageCount: z.number().min(0),
});

export type PaginationResponse = z.infer<typeof PaginationResponse>;

export const PaginationResponseBuilder = <ResponseSchema extends ZodTypeAny>(
  schema: ResponseSchema,
) =>
  z.object({
    result: z.array(schema),
    pagination: PaginationResponse,
  });
