import {
  DatePickerInput,
  DatePickerInputProps,
} from "@zakeke/zakeke-ui-widgets";

import InputWrapper, { InputWrapperProps } from "./InputWrapper.tsx";

type DatePickerProps = InputWrapperProps & DatePickerInputProps;

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  description = undefined,
  marginReset = false,
  labelClassName,
  required,

  // INPUT
  ...props
}) => {
  const wrapperProps = {
    label,
    description,
    marginReset,
    labelClassName,
    required,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <DatePickerInput
        required={required}
        {...props}
        className={"date-picker"}
      />
    </InputWrapper>
  );
};

export default DatePicker;
