import routes from "configuration/routes";
import React, { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AuthLayout from "@/components/AuthLayout";
import MainLayout from "@/components/MainLayout";
import ProtectedRoute from "@/components/ProtectedRoute";
import { Provider as AuthProvider } from "@/contexts/Auth.tsx";

import RootRouteRedirector from "./RootRouteRedirector";

const CompleteProfile = lazy(
  () => import("@/pages/Profile/CompleteProfile.tsx"),
);

const NotFound = lazy(() => import("@/pages/Errors/NotFound"));

const Login = lazy(() => import("@/pages/Auth/Login.tsx"));
const Reset = lazy(() => import("@/pages/Auth/Reset.tsx"));
const Register = lazy(() => import("@/pages/Auth/Register.tsx"));
const Integration = lazy(() => import("@/pages/Auth/Integration.tsx"));

const SkuBulkUpload = lazy(() => import("@/pages/SkuBulkUpload"));
const DemoRequest = lazy(() => import("./DemoRequest"));
const SkuDetail = lazy(() => import("./Product/skuDetail"));
const Profile = lazy(() => import("@/pages/Profile/Profile.tsx"));
const SkuList = lazy(() => import("./SkuList"));
const Sku3DModels = lazy(() => import("./Product/sku3DModels"));
const SkuReview = lazy(() => import("./Product/skuReview"));
const AssetList = lazy(() => import("./AssetLibrary/Listing.tsx"));
const GalleryDetail = lazy(() => import("./AssetLibrary/Detail.tsx"));
const Analytics = lazy(() => import("./Analytics"));
const Invite = lazy(() => import("./Invite"));
const Organizations = lazy(
  () => import("./Administration/Organizations/organizationList"),
);
const OrganizationDetail = lazy(
  () => import("./Administration/Organizations/organizationDetail"),
);
const OrganizationUserList = lazy(
  () => import("./Administration/Organizations/organizationUserList"),
);
const OrganizationUserDetail = lazy(
  () => import("./Administration/Organizations/organizationUserDetail"),
);
const Companies = lazy(() => import("./Administration/Companies/companyList"));
const CompanyDetail = lazy(
  () => import("./Administration/Companies/companyDetail"),
);
const CompanyUserList = lazy(
  () => import("./Administration/Companies/companyUserList"),
);
const CompanyUserDetail = lazy(
  () => import("./Administration/Companies/companyUserDetail"),
);
const Partners = lazy(() => import("./Administration/Partners/partnerList"));
const PartnerDetail = lazy(
  () => import("./Administration/Partners/partnerDetail"),
);
const PartnerUserList = lazy(
  () => import("./Administration/Partners/partnerUserList"),
);
const PartnerUserDetail = lazy(
  () => import("./Administration/Partners/partnerUserDetail"),
);
const UsersList = lazy(() => import("./Administration/Users/userList"));
const UserDetail = lazy(() => import("./Administration/Users/userDetail"));
const TagsListing = lazy(() => import("./Tag/Listing"));

const EstimatesListing = lazy(() => import("./Estimate/Listing"));
const EstimatesDetail = lazy(() => import("./Estimate/Detail"));

export default function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* routes without main layout */}
          <Route>
            <Route path="/" element={<RootRouteRedirector />} />
            <Route element={<AuthLayout />}>
              <Route path={routes.signIn.path} element={<Login />} />
              <Route path={routes.resetPassword.path} element={<Reset />} />
              <Route path={routes.signUp.path} element={<Register />} />
              <Route path={routes.accessJwt.path} element={<Integration />} />
            </Route>
            <Route
              path={routes.completeProfile.path}
              element={
                <ProtectedRoute requiresCompletedProfile={false}>
                  <CompleteProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* routes with main layout */}
          <Route element={<MainLayout />}>
            <Route path="/404" element={<NotFound />} />
            <Route
              path={routes.gallery.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={routes.gallery.userGroupRestrictions}
                >
                  <React.Suspense>
                    <AssetList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.galleryDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.galleryDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <GalleryDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.skuListDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.skuListDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <SkuDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.skuList.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={routes.skuList.userGroupRestrictions}
                >
                  <React.Suspense>
                    <SkuList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.skuList3DModels.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.skuList3DModels.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <Sku3DModels />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.skuBulkUpload.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.skuBulkUpload.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <SkuBulkUpload />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.skuReview.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={routes.skuReview.userGroupRestrictions}
                >
                  <React.Suspense>
                    <SkuReview />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.tagsListing.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.tagsListing.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <TagsListing />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.invite.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={routes.invite.userGroupRestrictions}
                >
                  <React.Suspense>
                    <Invite />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.profile.path}
              element={
                <ProtectedRoute>
                  <React.Suspense>
                    <Profile />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.analytics.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={routes.analytics.userGroupRestrictions}
                >
                  <React.Suspense>
                    <Analytics />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.requestDemo.path}
              element={
                <ProtectedRoute>
                  <React.Suspense>
                    <DemoRequest />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            {/* Estimates start */}
            <Route path="/requests" element={<Navigate to="/estimates" />} />
            <Route
              path="/estimates"
              element={
                <ProtectedRoute
                  userGroupRestrictions={[
                    "ffsuperadmin",
                    "ffadmin",
                    "orgadmin",
                    "pm",
                    "customer",
                  ]}
                >
                  <React.Suspense>
                    <EstimatesListing />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="/estimates/:id"
              element={
                <ProtectedRoute
                  userGroupRestrictions={[
                    "ffsuperadmin",
                    "ffadmin",
                    "orgadmin",
                    "pm",
                    "customer",
                  ]}
                >
                  <React.Suspense>
                    <EstimatesDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            {/* Estimates end */}

            <Route
              path={routes.adminOrganizations.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminOrganizations.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <Organizations />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminOrganizationDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminOrganizationDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <OrganizationDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminOrganizationUsers.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminOrganizationUsers.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <OrganizationUserList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminOrganizationUserDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminOrganizationUserDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <OrganizationUserDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminCompanies.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminCompanies.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <Companies />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminCompanyDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminCompanyDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <CompanyDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminCompanyUsers.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminCompanyUsers.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <CompanyUserList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminCompanyUserDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminCompanyUserDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <CompanyUserDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartners.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartners.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <Partners />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartnerDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartnerDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <PartnerDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartner3DArtists.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartner3DArtists.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <PartnerUserList role="artist3d" />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartner3DArtistsDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartner3DArtistsDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <PartnerUserDetail role="artist3d" />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartnerProjectManagers.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartnerProjectManagers.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <PartnerUserList role="pm" />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminPartnerProjectManagersDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminPartnerProjectManagersDetail
                      .userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <PartnerUserDetail role="pm" />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminUsers.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminUsers.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <UsersList />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.adminUserDetail.path}
              element={
                <ProtectedRoute
                  userGroupRestrictions={
                    routes.adminUserDetail.userGroupRestrictions
                  }
                >
                  <React.Suspense>
                    <UserDetail />
                  </React.Suspense>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
