import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useEffect, useState } from "react";
import { useTranslation as i18nUseTranslation } from "react-i18next";

import { Language, LocalizationNamespace } from "@/@types";
import { createLogger } from "@/libs";

const DEFAULT_LANGUAGE: Language = "en";
const logger = createLogger("useI18n.hook");

export function useI18n(
  ns: LocalizationNamespace | ReadonlyArray<LocalizationNamespace> = "common",
) {
  const [t, { changeLanguage: i18nChangeLanguage, language }] =
    i18nUseTranslation(ns);

  const lang = language as Language;

  function getCountry(l: Language): Language {
    if (!l) return DEFAULT_LANGUAGE;
    const c = l.split("-")[0];
    return c as Language;
  }

  function getRegionCode(l: Language): string | null {
    if (!l) return null;
    const c = l.split("-");
    return c.length > 1 ? c[1] : null;
  }

  const [country, setCountry] = useState<Language>(getCountry(lang));
  const [regionCode, setRegionCode] = useState<string | null>(
    getRegionCode(lang),
  );

  const changeLanguage = async (lng: Language) => {
    i18nChangeLanguage(lng);
    try {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, { locale: lng });
    } catch (error) {
      logger.error(error as Error, "Error while updating user attributes");
    }
  };

  useEffect(() => {
    setCountry(getCountry(lang));
    setRegionCode(getRegionCode(lang));
  }, [lang]);

  return { t, changeLanguage, language: lang, country, regionCode };
}
